<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g fill="none" fill-rule="nonzero">
      <path fill="#0057FF" d="M0 0h25v25H0z" />
      <g fill="#FFF">
        <path
          d="M14.92 7.096h4.936v1.238H14.92zM21.822 14.056c0 .223-.009.438-.027.644h-6.35c-.059 1.412.963 2.252 2.125 2.252.823 0 1.547-.44 1.964-1.187h2.079c-.57 2.099-2.246 2.972-4.225 2.972-2.591 0-4.434-2.096-4.434-4.68 0-2.586 1.986-4.682 4.434-4.682 2.449 0 4.434 2.096 4.434 4.681zm-6.377-.919h3.906c-.155-1.163-.97-1.904-1.953-1.904-.983 0-1.798.741-1.953 1.904zM12.193 14.254c.16 1.796-.16 2.91-1.978 3.814-.68.339-1.533.445-2.333.445h-5.76V6.303h6.337c.786 0 3.214.471 3.228 2.824.01 1.627-.904 2.37-1.667 2.727 0 0 2.013.605 2.173 2.4zm-2.595.517c0-1.714-1.919-1.669-1.919-1.669H4.787v3.338h2.892s1.92.044 1.92-1.67zm-.476-4.963c0-1.436-1.609-1.399-1.609-1.399H4.787v2.799h2.726s1.61.037 1.61-1.4z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "behance"
};
</script>
