<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="78" height="4" viewBox="0 0 78 4">
    <path fill="#CBCBCB" fill-rule="evenodd" d="M0 0h78v4H0z"/>
</svg>
</template>

<script>
export default {
  name: "regular-line"
};
</script>
