<template>
  <section id="projects">
    <template>
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap align-center justify-center>
          <!-- COMPANY ICONS -->
          <!-- <v-flex xs10 md11 class="pb-5">
            <v-img  
              v-if="$vuetify.breakpoint.width <= '960'"
              contain
              :src="companyiconsM"
              alt="Company Icons"
            ></v-img>
            <v-img
              v-else
              contain
              :src="companyicons"
              alt="Company Icons" 
            ></v-img>
          </v-flex> -->
        </v-layout>
        <v-layout row wrap justify-center>
          <v-flex
            xs12
            md6
            :class="[
              $vuetify.breakpoint.name === 'xs' ? 'py-3' : 'pa-3 flex-item',
            ]"
          >
            <router-link to="/projects/dudas-new-animations" exact>
              <div
                v-on:mouseover="handleCardMouseOver"
                v-on:mousemove="handleCardMouseMove"
                v-on:mouseout="handleCardMouseOut"
              >
                <v-card
                  flat
                  dark
                  style="background-color: transparent"
                  :class="[
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'xl'
                      ? 'ma-auto rotatable'
                      : 'ma-auto non-rotatable',
                  ]"
                >
                  <div
                    :style="{
                      background: `linear-gradient(96deg, #f66035 4%, #ff3300)`,
                      pointerEvents: 'none',
                    }"
                  >
                    <v-card-title primary-title>
                      <div class="card-title text-xs-center">
                        <h1 class="project-title pb-3">
                          Duda’s new animations
                        </h1>
                      </div>
                    </v-card-title>
                    <v-img
                      aspect-ratio="1.25"
                      :src="animationsImg"
                      contain
                      :class="{ wide: true }"
                    ></v-img>
                  </div>
                </v-card>
              </div>
            </router-link>
          </v-flex>
          <v-flex
            xs12
            md6
            :class="[
              $vuetify.breakpoint.name === 'xs' ? 'py-3' : 'pa-3 flex-item',
            ]"
          >
            <router-link to="/projects/dudas-new-text-editor" exact>
              <div
                v-on:mouseover="handleCardMouseOver"
                v-on:mousemove="handleCardMouseMove"
                v-on:mouseout="handleCardMouseOut"
              >
                <v-card
                  flat
                  dark
                  style="background-color: transparent"
                  :class="[
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'xl'
                      ? 'ma-auto rotatable'
                      : 'ma-auto non-rotatable',
                  ]"
                >
                  <div
                    :style="{
                      background: `linear-gradient(96deg, #f66035 4%, #ff3300)`,
                      pointerEvents: 'none',
                    }"
                  >
                    <v-card-title primary-title>
                      <div class="card-title text-xs-center">
                        <h1 class="project-title pb-3">
                          Duda’s new text editor
                        </h1>
                      </div>
                    </v-card-title>
                    <v-img
                      aspect-ratio="1.25"
                      :src="textEditor22"
                      contain
                      :class="{ wide: true }"
                    ></v-img>
                  </div>
                </v-card>
              </div>
            </router-link>
          </v-flex>
          <v-flex
            xs12
            md6
            v-for="project in projects"
            :key="project.id"
            :class="[
              $vuetify.breakpoint.name === 'xs' ? 'py-3' : 'pa-3 flex-item',
            ]"
          >
            <router-link
              :to="project.private ? '' : `/projects/${project.title}`"
              exact
            >
              <div
                v-on:mouseover="handleCardMouseOver"
                v-on:mousemove="handleCardMouseMove"
                v-on:mouseout="handleCardMouseOut"
              >
                <v-card
                  flat
                  dark
                  style="background-color: transparent"
                  :class="[
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'xl'
                      ? 'ma-auto rotatable'
                      : 'ma-auto non-rotatable',
                  ]"
                >
                  <div
                    :style="{
                      background: `linear-gradient(${project.gradiant})`,
                      pointerEvents: 'none',
                    }"
                  >
                    <div
                      v-if="project.private"
                      class="text-xs-center private-chip"
                    >
                      <v-img contain :src="privateSrc"></v-img>
                    </div>
                    <!-- <v-card-text class="px-0">6</v-card-text> -->
                    <v-card-title primary-title>
                      <div class="card-title text-xs-center">
                        <h1 class="project-title pb-3">{{ project.title }}</h1>
                        <!-- <span class="subtitle mt-3">{{ project.subtitle }}</span> -->
                      </div>
                    </v-card-title>
                    <v-img
                      aspect-ratio="1.25"
                      :src="
                        require(`@assets/images/projects/${project.imgLink}`)
                      "
                      contain
                      :class="{ wide: project.wide }"
                    ></v-img>
                  </div>
                </v-card>
              </div>
            </router-link>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      hover: false,
      projects: [],
      privateSrc: require("@assets/images/projects/private.png"),
      companyicons: require("@assets/images/icons-conpany.png"),
      companyiconsM: require("@assets/images/icons-companyM.png"),
      textEditor22: require("@assets/images/projects/Dudas-new-text-editor/new-text-editor-home.png"),
      animationsImg: require("@assets/images/projects/duda's-new-animations/new-animations.png"),
    };
  },
  created() {
    axios
      .get("projects.json")
      .then((result) => {
        this.projects = result.data.projects;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    handleCardMouseOver: function (event) {
      this.transformCard(event);
    },
    handleCardMouseMove: function (event) {
      this.transformCard(event);
    },
    handleCardMouseOut: function (event) {
      event.target.firstChild.style.transform = "rotate3d(0, 0, 0, 0deg)";
    },

    transformCard: function (event) {
      var inCardMouseX =
        event.clientX - this.getPositionInElement(event.target).x;
      var inCardMouseY =
        event.clientY - this.getPositionInElement(event.target).y;
      var cardWidth = event.target.offsetWidth;
      var cardHeight = event.target.offsetHeight;
      var percentageAcrossX = inCardMouseX / cardWidth;
      var percentageAcrossY = inCardMouseY / cardHeight;

      var weightX = 0.5 - percentageAcrossX;
      var weightY = 0.5 - percentageAcrossY;
      var distanceFromCenter = Math.pow(
        ((0.5 - percentageAcrossX) ^ 2) + ((0.5 - percentageAcrossY) ^ 2),
        0.5
      );
      var maxDegrees = 3;
      var degrees = distanceFromCenter * maxDegrees;

      event.target.firstChild.style.transform =
        "rotate3d(" + weightY + "," + -weightX + ", 0, " + degrees + "deg)";
    },

    getPositionInElement: function (el) {
      var xPosition = 0;
      var yPosition = 0;
      while (el) {
        if (el.tagName == "BODY") {
          var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
          var yScrollPos = el.scrollTop || document.documentElement.scrollTop;
          xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
          yPosition += el.offsetTop - yScrollPos + el.clientTop;
        } else {
          xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
          yPosition += el.offsetTop - el.scrollTop + el.clientTop;
        }
        el = el.offsetParent;
      }
      return {
        x: xPosition,
        y: yPosition,
      };
    },
  },
};
</script>

<style>
#projects {
  position: relative;
  z-index: 5;
}
a {
  text-decoration: none !important;
}
.rotatable {
  position: relative !important;
  transition-delay: 0s !important;
  transition-duration: 0.3s !important;
}
.non-rotatable {
  position: relative !important;
  transform: rotate3d(0, 0, 0, 0) !important;
}

.flex-item {
  max-width: 810px !important; /* max width of card will be 778 because it includes padding of 16px on each side */
  perspective: 200rem;
}

.card-title {
  width: 100%;
  margin-bottom: -5em;
}

h1.project-title {
  text-shadow: 0 5px 31px rgba(0, 0, 0, 0.2);
  -webkit-text-stroke: 2px #ffffff;
  -webkit-text-fill-color: #ffffff !important;
  -webkit-text-stroke: transparent;
  font-family: futura-pt;
  font-size: 42px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  letter-spacing: -0.86px;
  text-align: center;
  color: rgba(255, 255, 255, 0);
  /* color: #ffffff; */
  transition: 0.05s;
}

/* h1.active {
  -webkit-text-fill-color: #ffffff !important;
  -webkit-text-stroke: transparent;
} */

.subtitle {
  display: flex;
  width: 45%;
  margin: 0 auto;
  font-family: "futura-pt", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  text-align: center;
  color: #ffffff;
}

.subtitle:before,
.subtitle:after {
  color: white;
  content: "";
  flex: 1;
  border-bottom: groove 2px;
  margin: auto 0.35em;
  opacity: 0.13;
}

.private-chip {
  width: 105px;
  height: 75px;
  position: absolute;
  top: 4%;
  left: 4%;
}

@media (max-width: 680px) {
  .subtitle {
    width: 60%;
  }

  .card-title {
    margin-bottom: -6em;
  }
}

@media (max-width: 475px) {
  .card-title {
    margin-bottom: -4em;
  }

  .private-chip {
    width: 83px;
    height: 31px;
    object-fit: contain;
  }

  .subtitle {
    width: 80%;
  }

  .project-title {
    font-size: 28px;
    letter-spacing: -0.67px;
  }
}

@media (max-width: 400px) {
  .private-chip {
    width: 65px;
    left: 2px;
    object-fit: contain;
  }

  .project-title {
    font-size: 24px;
  }
}
</style>
