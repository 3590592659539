<template>
  <img :src="this.src" class="smart-phone" :style="style" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    topDiff: {
      type: Number,
      required: true
    },
    leftDiff: {
      type: Number,
      required: true
    },
    isAnimating: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    style() {
      var top = 'top: calc(50% - ' + this.instanceTopDiff + 'px);';
      var left = 'left: calc(50% - ' + this.instanceLeftDiff + 'px);';
      return top + left;
    }
  },

  methods: {
    randomize() {
      // top
      var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
      this.instanceTopDiff += (Math.random() * 4) * plusOrMinus;
      // top limit
      this.topLimit = 4;

      // direction
      this.isMotionDirectionUp = Math.random() >= 0.5;
    },
    move() {
      if (this.isAnimating === false) {
        // don't move phone while offscreen
        return;
      }

      // top
      var tempTopDiff = this.instanceTopDiff;
      if (tempTopDiff > this.topDiff + this.topLimit) {
        this.isMotionDirectionUp = false;
      } else if (tempTopDiff < this.topDiff - this.topLimit) {
        this.isMotionDirectionUp = true;
      }
      if (this.isMotionDirectionUp) {
        tempTopDiff += 0.5;
      } else {
        tempTopDiff -= 0.5;
      }
      this.instanceTopDiff = tempTopDiff;
    }
  },

  data() {
    return {
      instanceTopDiff: 0,
      instanceLeftDiff: 0,
      topLimit: 0,
      isMotionDirectionUp: true
    };
  },
  mounted() {
    this.instanceTopDiff = this.topDiff;
    this.instanceLeftDiff = this.leftDiff;
    // this.randomize()
    // setInterval(() => {
    //   this.move();
    // }, 40);
  }
};
</script>

<style scoped>
.smart-phone {
  position: absolute;
  width: 390px;
  height: 315px;
  background-size: contain;
  overflow: visible;
}
</style>
