<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="23"
    viewBox="0 0 33 23"
  >
    <path
      fill="#FFF"
      fill-rule="nonzero"
      d="M.45 10.401L10.27.455a1.52 1.52 0 0 1 2.17 0c.599.607.599 1.591 0 2.198L5.239 9.946h26.227c.847 0 1.534.696 1.534 1.554 0 .858-.687 1.554-1.534 1.554H5.239l7.2 7.293c.6.607.6 1.59 0 2.198a1.52 1.52 0 0 1-2.17 0L.45 12.599c-.599-.607-.599-1.591 0-2.198z"
    />
  </svg>
</template>

<script>
export default {
  name: "back-arrow"
};
</script>
