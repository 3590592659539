<template>
  <div id="single-project">
    <LeftNavBar />
    <Sidebar />
    <div class="black-box-container">
      <mobilePorject
        v-if="projectType === 'mobile'"
        :project="project"
        :nextProject="nextProject"
      ></mobilePorject>
      <desktopProject
        v-else-if="projectType === 'desktop'"
        :project="project"
        :nextProject="nextProject"
      ></desktopProject>
      <h1 v-else class="display-4">This project has no set type</h1>
    </div>
    <Footer />
    <Scrollbar class="hidden-sm-and-down" />
  </div>
</template>

<script>
import axios from "axios";
import Sidebar from "@components/_sidebarNav.vue";
import LeftNavBar from "@components/_leftSideNav.vue";
import mobilePorject from "@components/_singleProjectMobile.vue";
import desktopProject from "@components/_singleProjectDesktop.vue";
import Footer from "@components/_footer.vue";
import Scrollbar from "@components/_scrollBar.vue";

export default {
  data() {
    return {
      title: this.$route.params.projectTitle,
      projects: {},
      nextProject: {},
      project: {},
      projectType: "",
      publicProjects: ""
    };
  },
  components: {
    Sidebar,
    LeftNavBar,
    mobilePorject,
    desktopProject,
    Footer,
    Scrollbar
  },
  methods: {
    filterPublicProjects: function(project) {
      return !project.private;
    },
    setNextProject(id, publicProjects) {
      return (this.nextProject =
        publicProjects[publicProjects.findIndex(item => id === item.id) + 1] ||
        publicProjects.find(item => !item.private && id));
    }
  },
  created() {
    axios
      .get("../projects.json")
      .then(result => {
        // set projects array from data
        this.projects = result.data.projects;

        // Set current project
        this.project = this.projects.find(item => item.title == this.title);

        // check project type to render proper component
        if (this.project.type === "mobile") {
          this.projectType = this.project.type;
        } else if (this.project.type === "desktop") {
          this.projectType = this.project.type;
        } else {
          console.log("this project type is not set");
        }

        // set current project id
        let currentProjectId = this.project.id;

        // Set public projects object
        this.publicProjects = this.projects.filter(this.filterPublicProjects);

        // set next project
        this.setNextProject(currentProjectId, this.publicProjects);
      })
      .catch(err => console.log(err));
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }
};
</script>

<style scoped>
#single-project {
  position: relative;
}

.black-box-container {
  position: relative;
  width: 100%;
  background-color: #0b0b0b;
  z-index: 5;
}

@media (min-width: 1475px) {
  /* .additional-img {
    margin: 0 auto;
  } */
}
</style>
