import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import SingleProject from "@components/_singleProject.vue";

Vue.use(Router);

export default new Router({
  routes: [{
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import( /* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue")
    },
    {
      path: "/projects/dudas-new-animations",
      name: "dudas-new-animations",
      component: () => import("./views/dudas-new-animations.vue")
    },
    {
      path: "/projects/dudas-new-text-editor",
      name: "dudas-new-text-editor",
      component: () => import("./views/dudas-new-text-editor.vue")
    },
    {
      path: "/projects/:projectTitle",
      // name: "home",
      component: SingleProject
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
