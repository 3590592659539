<template>
  <section id="selfBelief">
    <v-layout wrap row justify-center class="custome-max-width">
      <v-flex xs12 class="text-xs-center ma-auto d-flex">
        <h1 class="py-5 section-title">My philosophy</h1>
      </v-flex>
      <v-flex class="pt-2" xs12 lg3 xl4 v-for="card in cards" :key="card.title">
        <v-card flat color="transparent">
          <v-card-title primary-title class="d-block">
            <v-img contain class="box-icon ma-auto" :src="card.icon"></v-img>
            <div>
              <div class="card-headline text-xs-center text-capitalize">
                {{ card.title }}
              </div>
            </div>
            <div class="card-paragraph">
              <span>{{ card.paragraph }}</span>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          icon: require("@assets/images/option.png"),
          title: "design thinking",
          paragraph:
            "I always see the design from a user’s perspective, which helps me to make sure we don’t skip any issue that might come up when the product is live."
        },
        // {
        //   icon: require("@assets/images/teamwork.png"),
        //   title: "limited edition",
        //   paragraph:
        //     "I limit the number of projects that I take each time, so each client gets the most of me."
        // },
        {
          icon: require("@assets/images/mvp.png"),
          title: "MVP awareness",
          paragraph:
            "I believe in MVPs. I think a good way to collect the maximum amount of validated learning about the customer with the least efforts, and to do it in a timely manner."
        },
        {
          icon: require("@assets/images/idea.png"),
          title: "I doubt everything",
          paragraph:
            "In every session I do, I put extra efforts and I question each decision made, each idea and each button placed."
        }
      ]
    };
  }
};
</script>

<style scoped>
.custome-max-width {
  max-width: 1800px;
  margin: 0 auto;
}
#selfBelief {
  padding-bottom: 100px;
  position: relative;
  position: relative;
  z-index: 5;
  margin-bottom: 30%;
}

.section-title {
  width: 349px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.box-icon {
  width: auto;
  height: 81px;
}

.card-headline {
  margin: 55px auto 21px auto !important;
  font-size: 24px !important;
  font-family: Futura-pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.card-paragraph {
  max-width: 365px;
  margin: 0 auto;
  opacity: 0.68;
  font-family: Futura-pt;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 475px) {
  #selfBelief {
    margin-bottom: 430px !important;
  }
  .section-title {
    width: 365px;
    font-size: 46px;
    font-weight: bold;
    text-align: center;
  }

  .card-headline {
    font-size: 28px !important;
  }

  .card-paragraph {
    width: 365px;
    max-height: 72px;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 130px;
  }
}

@media (max-width: 381px) {
  .card-paragraph {
    max-width: 280px;
  }
}
@media (max-width: 1903px) {
  .test {
    max-width: 1250px;
  }
}
</style>
