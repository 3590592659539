<template>
  <router-link to="/">
    <div class="hidden-md-and-down" @mouseover="hover = true" @mouseleave="hover = null" v-on:click="scrollToTopOfPage">
      <v-img class="dark-logo" :src="logoSrc" :style="hover ? { opacity: 0 } : { opacity: 1 }"></v-img>
      <v-img v-show="hover" class="animated" :src="animatedLogoSrc"></v-img>
    </div>
    <div class="hidden-lg-and-up" v-on:click="scrollToTopOfPage">
      <v-img class="dark-logo" :src="logoSrc"></v-img>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      hover: null,
      logoSrc: require("@assets/images/dark-logo.png"),
      animatedLogoSrc: require("@assets/images/dark-logo-animated.gif")
    };
  },
  methods: {
    scrollToTopOfPage() {
      // guard
      if (!this.$route.name == 'home') {
        return;
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
.dark-logo {
  position: fixed;
  top: 59px;
  left: 65px;
  width: 16%;
  max-width: 139px;
  min-width: 110px;
  z-index: 9;
}

.animated {
  position: fixed;
  top: 34px;
  left: 34.5px;
  width: 34%;
  max-width: 199px;
  min-width: 180px;
  z-index: 9;
}

.animated:hover {
  opacity: 1;
}

@media (max-width: 475px) {
  .dark-logo {
    left: 18px;
    top: 25.7px;
  }
}

@media (max-width: 400px) {
  .dark-logo {
    left: 18px;
    top: 25.7px;
  }
}
</style>
