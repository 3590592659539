<template>
  <div class="bouncing-smart-phones-container" v-observe-visibility="visibilityChanged">
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone1}`)" :topDiff="365" :leftDiff="440" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone2}`)" :topDiff="168" :leftDiff="490" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone3}`)" :topDiff="332" :leftDiff="133" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone4}`)" :topDiff="-44" :leftDiff="536" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone5}`)" :topDiff="140" :leftDiff="189" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone6}`)" :topDiff="350" :leftDiff="-178" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone7}`)" :topDiff="-61" :leftDiff="244" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone8}`)" :topDiff="111" :leftDiff="-136" :isAnimating="this.isAnimating" />
    <BouncingSmartPhone :src="require(`@assets/images/${project.bouncingPhones.phone9}`)" :topDiff="-90" :leftDiff="-61" :isAnimating="this.isAnimating" />
  </div>
</template>

<script>
import BouncingSmartPhone from "@components/_bouncingSmartPhone.vue";
import { ObserveVisibility } from "vue-observe-visibility";

export default {
  data() {
    return {
      isAnimating: false
    };
  },
  props: ["project"],
  components: {
    BouncingSmartPhone
  },
  directives: {
    ObserveVisibility
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      this.isAnimating = isVisible;
    },
  },
};
</script>

<style scoped>
.bouncing-smart-phones-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: visible;
}
</style>
