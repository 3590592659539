<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="29"
    height="29"
    viewBox="0 0 29 29"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="1"
        x2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#026fe5" />
        <stop offset="1" stop-color="#16a6fc" />
      </linearGradient>
    </defs>
    <g id="Group_1" data-name="Group 1" transform="translate(-587 -1843)">
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M16,28.923h0v-9.45h3.5a.5.5,0,0,0,.473-.342l1-3a.5.5,0,0,0-.316-.633.517.517,0,0,0-.158-.026H16v-3.5a1.5,1.5,0,0,1,1.5-1.5h3a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-3a5.516,5.516,0,0,0-5.5,5.5v3.5H8.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5H12v9.313a14.508,14.508,0,1,1,4,.138Z"
        transform="translate(587 1843)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Intersection_1"
        data-name="Intersection 1"
        d="M12,28.785V19.472H8.5a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5H12v-3.5a5.507,5.507,0,0,1,5.5-5.5h3a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5h-3a1.5,1.5,0,0,0-1.5,1.5v3.5h4.5a.52.52,0,0,1,.157.026.5.5,0,0,1,.316.633l-1,3a.5.5,0,0,1-.473.342H16v9.452a14.676,14.676,0,0,1-4-.138Z"
        transform="translate(587 1843)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "facebook"
};
</script>
