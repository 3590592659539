var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:([
    _vm.$vuetify.breakpoint.width <= 959
      ? { marginBottom: '471px' }
      : { marginBottom: '575px' }
  ]),attrs:{"id":"single-project-desktop"}},[_c('section',{style:({
      backgroundImage: ("-webkit-linear-gradient(" + (_vm.project.titleHeaderGradiant) + ")")
    }),attrs:{"id":"project-header"}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"justify-center",attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"mb-0",class:[
                  _vm.$vuetify.breakpoint.width <= 1060
                    ? 'project-title-mobile'
                    : 'project-title'
                ],style:({
                  backgroundImage: ("-webkit-linear-gradient(" + (_vm.project.titleGradiant) + ")")
                })},[_vm._v(" "+_vm._s(_vm.project.title)+" ")]),_c('div',{staticClass:"pb-2",class:[
                  _vm.$vuetify.breakpoint.width <= 1060
                    ? 'project-subtitle-extended-mobile'
                    : 'project-subtitle-extended'
                ]},[_vm._v(" "+_vm._s(_vm.project.subtitleExtended)+" ")]),_c('div',{staticClass:"pb-5 project-third-extended"},[_vm._v(" "+_vm._s(_vm.project.thirdSubtitle)+" ")])])])],1)],1)],1),_c('v-layout',{staticClass:"row wrap",class:[
        _vm.$vuetify.breakpoint.width >= 1426 ? 'justify-center' : 'justify-start'
      ],style:([_vm.isMobile ? { borderTop: '1px solid #262626' } : ''])},_vm._l((Object.entries(_vm.project.projectInfo)),function(ref,index){
      var title = ref[0];
      var value = ref[1];
return _c('v-flex',{key:index,staticClass:"px-3",class:[
          _vm.isMobile ? 'project-info-box-mobile xs6' : 'project-info-box shrink'
        ]},[_c('v-card',{attrs:{"flat":"","color":"transparent","height":"100%"}},[_c('v-card-title',[_c('div',{class:[_vm.isMobile ? 'mx-3' : '']},[_c('span',{staticClass:"d-block mb-2",class:[
                  _vm.$vuetify.breakpoint.width <= 1060
                    ? 'grey-title-mobile'
                    : 'grey-title'
                ]},[_vm._v(_vm._s(title))]),_c('span',{class:[
                  _vm.$vuetify.breakpoint.width <= 1060
                    ? 'headline-mobile'
                    : 'headline-desktop'
                ]},[_vm._v(_vm._s(value))])])])],1)],1)}),1),_c('v-spacer',{staticClass:"hidden-sm-and-down pb-5"})],1),_c('section',{attrs:{"id":"two-images"}},[_c('v-card',{style:({
        background: ("linear-gradient(" + (_vm.project.gradiant) + ")")
      }),attrs:{"flat":"","color":"transparent"}},[_c('v-layout',{staticClass:"img-container",attrs:{"row":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-img',{class:[
            _vm.$vuetify.breakpoint.width <= 600 ? 'left-img-mobile' : 'left-img'
          ],attrs:{"contain":"","src":require(("@assets/images/" + (_vm.project.image2)))}}),(_vm.project.addittional != '')?_c('v-img',{staticClass:"additional-img",attrs:{"contain":"","src":require(("@assets/images/" + (_vm.project.addittional)))}}):_vm._e()],1)],1)],1),_c('section',{attrs:{"id":"project-info"}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-layout',{staticStyle:{"margin-top":"5em"},attrs:{"row":"","wrap":"","align-start":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","md6":"","lg5":""}},[_c('v-card-title',{staticClass:"pb-2"},[_c('span',{class:[
                _vm.$vuetify.breakpoint.width <= 1060
                  ? 'grey-title-mobile'
                  : 'grey-title'
              ]},[_vm._v(_vm._s(_vm.project.aboutHeader))])]),_c('v-card-text',{staticClass:"pt-1"},[_c('span',{staticClass:"project-top-text"},[_vm._v(_vm._s(_vm.project.about))])])],1),_c('v-flex',{attrs:{"xs11":"","md6":"","lg5":""}},[_c('v-card-title',{staticClass:"pb-2"},[_c('span',{class:[
                _vm.$vuetify.breakpoint.width <= 1060
                  ? 'grey-title-mobile'
                  : 'grey-title'
              ]},[_vm._v("The Challenge")])]),_c('v-card-text',{staticClass:"pt-1"},[_c('span',{staticClass:"project-top-text"},[_vm._v(_vm._s(_vm.project.challenge))])])],1)],1)],1)],1),_c('v-spacer'),_c('section',{attrs:{"id":"project-mockup"}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-layout',{style:([
          _vm.$vuetify.breakpoint.width <= 1750
            ? { width: '100%' }
            : { width: '70%', margin: '0 auto' }
        ]),attrs:{"row":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-img',{staticClass:"max-width-img",style:([
            _vm.$vuetify.breakpoint.name === 'xs'
              ? { minHeight: '414px' }
              : { minHeight: '814px' }
          ]),attrs:{"src":require(("@assets/images/" + (_vm.project.mockupBack)))}},[_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-img',{staticClass:"front-text",attrs:{"src":require(("@assets/images/" + (_vm.project.mockupFront)))}})],1)],1)],1)],1)],1),_c('section',{attrs:{"id":"project-scath"}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{staticClass:"pb-5 pt-5",attrs:{"xs10":"","md10":""}},[(_vm.$vuetify.breakpoint.width <= '960')?_c('v-img',{attrs:{"contain":"","src":require(("@assets/images/" + (_vm.project.scatchImgM))),"alt":"Company Icons"}}):_c('v-img',{attrs:{"contain":"","src":require(("@assets/images/" + (_vm.project.scatchImg))),"alt":"Company Icons"}})],1)],1)],1),_c('v-spacer'),_c('section',{attrs:{"id":"parallax"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.$vuetify.breakpoint.width <= '960')?_c('v-parallax',{attrs:{"height":"530","src":require(("@assets/images/" + (_vm.project.parallaxImgM)))}}):_c('v-parallax',{attrs:{"height":"606","src":require(("@assets/images/" + (_vm.project.parallaxImg)))}})],1)],1)],1),_c('v-spacer'),_c('section',{class:[_vm.isMobile ? '' : 'container'],attrs:{"id":"project-showcase-desktop"}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-start":"","justify-center":"","fill-height":""}},[_vm._v(" "+_vm._s(_vm.setProjectShowcase())+" "),_vm._l((_vm.project.projectShowcaseDesktop),function(item,index){return _c('v-flex',{key:index,class:[_vm.isMobile ? '' : 'pa-4 mb-3'],staticStyle:{"minWidth":"'230px'","minHeight":"'411px'"},attrs:{"d-flex":"","xs10":"","md6":"","flat":""}},[_c('v-card',{staticClass:"transparent showcase-img",attrs:{"flat":"","width":"100%"}},[_c('v-card-title',{style:([
              _vm.$vuetify.breakpoint.name === 'xs'
                ? { maxWidth: '174px', fontSize: '16px' }
                : { maxWidth: '230px', fontSize: '18px' }
            ])},[_c('div',{staticClass:"text-xs-center"},[_c('span',[_vm._v(_vm._s(item.title))])])]),_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":""}},[_c('v-img',{staticClass:"mx-1",attrs:{"contain":"","src":require(("@assets/images/" + (item.imgsrc)))}})],1)],1)],1)})],2)],1),_c('v-spacer',{staticClass:"my-4"}),_c('section',{staticClass:"container",attrs:{"id":"why"}},[_c('v-card',{staticClass:"why-container py-2",class:[_vm.isMobile ? 'py-2' : 'py-2 pl-4 pr-5']},[_c('v-img',{class:[
          _vm.isMobile ? 'why-img why-img-mobile' : 'why-img why-img-desktop'
        ],attrs:{"src":require(("@assets/images/" + (_vm.project.whyImg)))}}),_c('v-card-title',[_c('div',{staticClass:"why-title"},[_vm._v("Why I did what I did?")])]),_c('v-card-text',{staticClass:"why-text"},[_vm._v(_vm._s(_vm.project.whyText))])],1)],1),_c('v-spacer',{staticClass:"my-2"}),_c('section',{attrs:{"id":"client-testimony"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg5":"","xl5":"","offset-lg1":""}},[_c('v-card',{staticClass:"ma-auto transparent",attrs:{"flat":""}},[(("" + (_vm.project.type)) == 'desktop')?_c('v-img',{staticClass:"image-testimony-computer",style:([
                _vm.$vuetify.breakpoint.name === 'xs'
                  ? { height: '378px' }
                  : {
                      maxHeight: '869px',
                      backgroundSize: 'contain',
                      marginTop: '-5%',
                      width: '140%'
                    }
              ]),attrs:{"src":require(("@assets/images/" + (_vm.project.ninePhones)))}}):_vm._e(),(("" + (_vm.project.type)) == 'mobile')?_c('BouncingSmartPhones',{style:([
                _vm.$vuetify.breakpoint.name === 'xs'
                  ? { height: '378px' }
                  : {
                      maxHeight: '869px',
                      marginTop: '-5%',
                      width: '100%'
                    }
              ])}):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-card',{staticClass:"ma-auto transparent",attrs:{"flat":""}},[_c('v-card-text',[_c('blockquote',{staticClass:"mr-5",class:[
                  _vm.$vuetify.breakpoint.width <= '1263'
                    ? 'testimony-mobile'
                    : 'testimony-desktop'
                ],style:([
                  _vm.$vuetify.breakpoint.width <= '1499'
                    ? { fontSize: '45px' }
                    : { fontSize: '56px' }
                ])},[_c('TypewriterTestimony',{attrs:{"project":_vm.project,"hasFinishedRendering":this.hasFinishedRendering}})],1)])],1)],1)],1)],1)],1),(!_vm.isMobile)?_c('v-spacer',{staticClass:"my-5"}):_vm._e(),_c('section',{attrs:{"id":"project-showcase-mobile"}},[_c('v-layout',{staticClass:"pt-5 px-3",attrs:{"row":"","wrap":"","align-start":"","justify-center":"","fill-height":""}},_vm._l((_vm.project.projectShowCase2.slice(0, 4)),function(item,index){return _c('v-flex',{key:index,attrs:{"d-flex":"","xs6":"","md2":"","flat":"","min-width":"230px"}},[_c('v-card',{staticClass:"pa-2 showcase-img-2",attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"ma-auto",style:([
              _vm.$vuetify.breakpoint.name === 'xs'
                ? { maxWidth: '174px' }
                : { maxWidth: '230px' }
            ])},[_c('div',{staticClass:"text-xs-center"},[_c('span',[_vm._v(_vm._s(item.title))])])]),_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":""}},[_c('v-img',{staticClass:"mx-1",attrs:{"contain":"","src":require(("@assets/images/" + (item.imgSrc)))}})],1)],1)],1)}),1),_c('v-layout',{staticClass:"pb-5 px-3",attrs:{"row":"","wrap":"","align-start":"","justify-center":"","fill-height":""}},_vm._l((_vm.project.projectShowCase2.slice(4, 8)),function(item,index){return _c('v-flex',{key:index,attrs:{"d-flex":"","xs6":"","md2":"","flat":""}},[_c('v-card',{staticClass:"pa-2 showcase-img-2",attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"ma-auto",style:([
              _vm.$vuetify.breakpoint.name === 'xs'
                ? { maxWidth: '174px' }
                : { maxWidth: '230px' }
            ])},[_c('div',{staticClass:"text-xs-center"},[_c('span',[_vm._v(_vm._s(item.title))])])]),_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":""}},[_c('v-img',{attrs:{"mx-1":"","contain":"","src":require(("@assets/images/" + (item.imgSrc)))}})],1)],1)],1)}),1)],1),(_vm.project.id !== '6')?_c('section',{attrs:{"id":"next-project"}},[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","md5":"","lg5":"","xl5":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0 my-0"},[_c('div',{staticClass:"next-project d-block"},[_vm._v("Next project")])]),_c('v-card-title',[_c('h3',{staticClass:"next-project-title d-block mt-0",class:[
                  _vm.$vuetify.breakpoint.width <= 1060
                    ? 'project-title-mobile-next'
                    : 'project-title'
                ],style:({
                  backgroundImage: ("-webkit-linear-gradient(" + (_vm.nextProject.titleGradiant) + ")")
                })},[_vm._v(" "+_vm._s(_vm.nextProject.title)+" ")])]),_c('v-card-text',{staticClass:"py-0 my-0"},[_c('span',{staticClass:"next-project-category"},[_vm._v(_vm._s(_vm.nextProject.projectInfo.Type))])])],1)],1),_c('v-flex',{attrs:{"xs8":"","md5":"","lg7":"","xl5":"","offset-xs4":"","offset-md0":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"next-project-subtitle project-subtitle-extended project-subtitle text-xs-right"},[_c('div',{style:([
                  _vm.isMobile ? { fontSize: '21px' } : { fontSize: '28px' }
                ])},[_vm._v(" "+_vm._s(_vm.nextProject.subtitleExtended)+" ")])])],1)],1),_c('v-flex',{staticClass:"mt-5",attrs:{"xs10":"","md10":"","lg12":"","xl10":""},on:{"mouseover":_vm.mouseHandler,"mouseleave":_vm.mouseHandler}},[_c('router-link',{attrs:{"to":_vm.nextProjectLink}},[_c('div',{style:([
                _vm.isMobile
                  ? {
                      position: 'relative',
                      marginBottom: '40px'
                    }
                  : { marginBottom: '40px', overflow: 'hidden' }
              ])},[_c('v-card',{staticStyle:{"paddingTop":"40px"},style:({
                  background: ("linear-gradient(" + (_vm.nextProject.gradiant) + ")")
                }),attrs:{"flat":""}},[_c('v-img',{staticClass:"mx-5 my-0 next-project-img",style:([
                    _vm.isMobile
                      ? ''
                      : { paddingLeft: '150px', paddingRight: '150px' }
                  ]),attrs:{"contain":"","src":require(("@assets/images/" + (_vm.nextProject.nextProjectImg)))}})],1),(_vm.isMobile)?_c('span',{staticClass:"big-btn-arrow",style:({
                  background: ("url(@assets/images/" + (_vm.nextProject.arrow) + ") center center, linear-gradient(" + (_vm.nextProject.gradiant) + ")")
                })},[_c('v-img',{staticClass:"arrow",attrs:{"src":require(("@assets/images/" + (_vm.nextProject.arrow)))}})],1):_vm._e()],1)])],1)],1)],1)],1):_c('section',{attrs:{"id":"next-project"}},[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":"","md5":"","lg5":"","xl5":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0 my-0"},[_c('div',{staticClass:"next-project d-block"},[_vm._v("Next project")])]),_c('v-card-title',[_c('h3',{staticClass:"next-project-title d-block mt-0",class:[
                  _vm.$vuetify.breakpoint.width <= 1060
                    ? 'project-title-mobile-next'
                    : 'project-title'
                ],staticStyle:{"backgroundImage":"linear-gradient(96deg, #f66035 4%, #ff3300)"}},[_vm._v(" Duda's new animations ")])]),_c('v-card-text',{staticClass:"py-0 my-0"},[_c('span',{staticClass:"next-project-category"},[_vm._v("Feature")])])],1)],1),_c('v-flex',{attrs:{"xs8":"","md5":"","lg7":"","xl5":"","offset-xs4":"","offset-md0":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"next-project-subtitle project-subtitle-extended project-subtitle text-xs-right"},[_c('div',{style:([
                  _vm.isMobile ? { fontSize: '21px' } : { fontSize: '28px' }
                ])},[_vm._v(" Next generation animations of the Duda website builder platform ")])])],1)],1),_c('v-flex',{staticClass:"mt-5",attrs:{"xs10":"","md10":"","lg12":"","xl10":""},on:{"mouseover":_vm.mouseHandler,"mouseleave":_vm.mouseHandler}},[_c('router-link',{attrs:{"to":_vm.nextProjectLink}},[_c('div',{style:([
                _vm.isMobile
                  ? {
                      position: 'relative',
                      marginBottom: '40px'
                    }
                  : { marginBottom: '40px', overflow: 'hidden' }
              ])},[_c('v-card',{staticStyle:{"paddingTop":"40px"},style:({
                  background: "linear-gradient(118deg, #f66035 3%, #ff3300 100%)"
                }),attrs:{"flat":""}},[_c('v-img',{staticClass:"mx-5 my-0 next-project-img",style:([
                    _vm.isMobile
                      ? ''
                      : { paddingLeft: '150px', paddingRight: '150px' }
                  ]),attrs:{"contain":"","src":_vm.nextImg}})],1),(_vm.isMobile)?_c('span',{staticClass:"big-btn-arrow",style:({
                  background: ("url(@assets/images/" + (_vm.nextProject.arrow) + ") center center, linear-gradient(118deg, #f66035 3%, #ff3300 100%)")
                })},[_c('v-img',{staticClass:"arrow",attrs:{"src":require(("@assets/images/" + (_vm.nextProject.arrow)))}})],1):_vm._e()],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }