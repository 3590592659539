<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="101"
    height="101"
    viewBox="0 0 101 101"
  >
    <defs>
      <circle id="b" cx="26.5" cy="26.5" r="26.5" />
      <filter
        id="a"
        width="247.2%"
        height="247.2%"
        x="-73.6%"
        y="-67.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="12.5"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.181162587 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(24 21)">
        <use fill="#000" filter="url(#a)" xlink:href="#b" />
        <use fill="#FFF" fill-opacity="0.751" xlink:href="#b" />
      </g>
      <path
        fill="#000"
        fill-rule="nonzero"
        d="M52.105 56.82l-.007.032V42.8l4.315 4.418c.21.216.496.335.796.335.3 0 .582-.12.794-.335l.67-.688c.21-.216.327-.504.327-.811 0-.307-.116-.596-.326-.812l-7.38-7.572a1.095 1.095 0 0 0-.793-.335c-.301 0-.583.118-.794.335l-7.38 7.572c-.211.216-.327.504-.327.812 0 .307.116.595.326.811l.67.688c.21.216.492.335.791.335.3 0 .566-.12.776-.335l4.34-4.468v14.085c0 .633.531 1.165 1.147 1.165h.948c.617 0 1.107-.548 1.107-1.18z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "go-up"
};
</script>
