<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="26"
    viewBox="0 0 34 14"
  >
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="#FFF"
      stroke-linecap="square"
      stroke-width="2"
      d="M14.5 12.912L1.33 1 14.5 12.912 27.67 1 14.5 12.912zm0 0L1.134 25 14.5 12.912 27.866 25 14.5 12.912z"
    />
  </svg>
</template>

<script>
export default {
  name: "dark-close"
};
</script>
