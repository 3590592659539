var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('v-img',{attrs:{"max-height":_vm.footerHeight,"absolute":"","src":_vm.footerImageSrc}},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","row":""}},[_c('v-flex',{staticClass:"footer-text footer-text-1 text-xs-center",style:([
          _vm.$vuetify.breakpoint.name === 'xs' ? '' : { paddingTop: '1.75em' }
        ]),attrs:{"xs12":""}},[_vm._v("Do you have a question or")])],1),_c('v-layout',{attrs:{"align-center":"","justify-center":"","row":""}},[_c('v-flex',{staticClass:"footer-text footer-text-2 text-xs-center",style:([
          _vm.$vuetify.breakpoint.name === 'xs' ? '' : { marginBottom: '0.75em' }
        ]),attrs:{"xs12":""}},[_vm._v(" anything else? "),_c('router-link',{attrs:{"to":"/contact"}},[_c('span',{class:[
              _vm.$vuetify.breakpoint.name === 'xs'
                ? 'outline-text-mobile'
                : 'outline-text'
            ]},[_vm._v("Contact me")])])],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":"","container":""}},[_c('v-flex',{staticClass:"text-xs-center mx-0 icon-box"},_vm._l((_vm.socialLinks),function(link,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var _obj;

            var hover = ref.hover;return _c('v-btn',{staticClass:"text-none social-netowrk-btn",class:( _obj = {}, _obj[((link.name) + "-icon")] = hover, _obj ),attrs:{"flat":"","href":link.url,"target":"_blank"},on:{"mouseover":function($event){hover = true},"mouseleave":function($event){hover = null}}},[_c('v-icon',{staticClass:"mr-1 hidden-xs-only",style:({
                visibility: hover ? 'visible' : 'hidden'
              })},[_vm._v(_vm._s(link.icon))]),_vm._v(" "+_vm._s(link.name)+" ")],1)}}],null,true)})}),1)],1)],1),_c('v-footer',{staticClass:"text-xs-center mb-2",attrs:{"absolute":"","color":"transparent"}},[_c('div',{staticClass:"copyright-text",staticStyle:{"width":"100%"}},[_vm._v(" All rights reserved Itay Blayer ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }