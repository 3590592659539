<template>
  <div>
    <v-btn fixed class="dark-burger" icon @click.stop="drawer = !drawer">
      <v-icon v-if="drawer">$vuetify.icons.close</v-icon>
      <v-icon v-if="!drawer">$vuetify.icons.burger</v-icon>
    </v-btn>
    <router-link to="/contact">
      <v-btn icon fixed class="icon-chat">
        <img
          class="icon-chat-size"
          src="../assets/images/chat.png"
          alt="Chat Icon"
        />
      </v-btn>
    </router-link>
    <!-- Navigation drawer -->
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      dark
      right
      temporary
      width="500"
      fixed
    >
      <!-- Page Links section -->
      <section id="page-links">
        <v-list class="nav-list">
          <v-list-tile
            v-for="(link, index) in links"
            :key="index"
            class="text-uppercase list-item"
          >
            <router-link :to="link.route" exact>
              <v-list-tile-content class="nav-list-title">
                {{ link.title }}
              </v-list-tile-content>
            </router-link>
          </v-list-tile>
        </v-list>
      </section>
      <v-divider class="divider"></v-divider>

      <!-- secial media links -->
      <section id="social-links">
        <v-layout column align-start justify-start>
          <v-flex
            xs12
            class="text-xs-center icon-box"
            v-for="(link, index) in socialLinks"
            :key="index"
          >
            <v-hover>
              <v-btn
                slot-scope="{ hover }"
                @mouseover="hover = true"
                @mouseleave="hover = null"
                flat
                class="text-none social-netowrk-btn pl-4"
                :href="link.url"
                target="_blank"
                :class="{ [`${link.name}-icon`]: hover }"
              >
                <v-icon class="hidden-xs-only" v-if="hover">{{
                  link.icon
                }}</v-icon>
                {{ link.name }}
              </v-btn>
            </v-hover>
          </v-flex>
        </v-layout>
      </section>

      <v-divider class="divider"></v-divider>

      <!-- contact info section -->
      <section id="contact-info">
        <v-layout column justify-start align-start class="ml-4">
          <v-flex class="xs12">
            <v-card class="transparent">
              <v-card-text class="my-0 py-0">
                <!-- <span class="d-flex contact-city">{{ address.city }}</span>
                <span class="d-flex contact-street">{{ address.street }}</span> -->
                <span class="d-flex contact-email">
                  <a target="_blank" href="mailto:hello@itayblayer.com">
                    {{ address.email }}
                  </a>
                </span>
                <span class="d-flex contact-tel">
                  <a href="tel:+972 545855815">{{ address.tel }}</a>
                </span>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </section>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { eventBus } from "@src/main";
export default {
  data() {
    return {
      socialLinks: [
        {
          name: "LinkedIn",
          icon: "$vuetify.icons.Linkedin",
          url: "https://il.linkedin.com/in/itayblayer"
        },
        {
          name: "Facebook",
          icon: "$vuetify.icons.Facebook",
          url: "https://www.facebook.com/itayblayer/"
        },
        {
          name: "Instagram",
          icon: "$vuetify.icons.Instagram",
          url: "https://www.instagram.com/itayblayer/"
        }

        // {
        //   name: "Dribbble",
        //   icon: "$vuetify.icons.Dribbble",
        //   url: "https://dribbble.com/itayblayer"
        // },
        // {
        //   name: "Behance",
        //   icon: "$vuetify.icons.Behance",
        //   url: "https://www.behance.net/itayblayer"
        // }
      ],
      drawer: false,
      links: [
        { title: "work", route: "/" },
        { title: "about", route: "/about" },
        { title: "contact", route: "/contact" }
      ],
      address: {
        city: "Tel Aviv",
        street: "Menahem Begin 144",
        email: "itayblayer@gmail.com",
        tel: "+972 545855815"
      }
    };
  },
  mounted() {
    // emit social links data (data used in footer component)
    eventBus.$emit("social", this.socialLinks);
  }
};
</script>

<style scoped>
.text-size-custome {
  font-size: 50px !important;
}
.v-btn.dark-burger::before,
.v-btn.dark-burger::after {
  color: transparent;
  /* pointer-events: none; */
}

.dark-burger {
  z-index: 10;
  top: 65px;
  right: 63px;
}
.icon-chat {
  z-index: 6;
  top: 64px;
  right: 130px;
  color: transparent;
}
.icon-chat-size {
  width: 56px;
  height: 56px;
  /* background: transparent; */
}
.v-navigation-drawer {
  background: #0b0b0b !important;
}

.nav-list {
  margin: 60px 0 0 30px;
}

.list-item {
  margin: 20px 0;
}

.nav-list-title {
  margin: 15px 0;
  -webkit-text-stroke: 2px #ffffff;
  font-family: "futura-pt", sans-serif;
  font-size: 46px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 40px !important;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0) !important;
  height: 100% !important;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  border-bottom: 1px solid transparent;
}

a.router-link-exact-active.router-link-active {
  -webkit-text-fill-color: white !important;
  color: white;
}

/* ========================================================================================= */
/* code to animate border bottom to slide from left to right - taken off at client's request */

/* a.router-link-exact-active.router-link-active .nav-list-title::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #ffffff;
}

.nav-list-title::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: width 0.4s;
  margin-top: 10px;
}

.nav-list-title:hover::after {
  width: 100%;
} */

/* ========================================================================================= */

.nav-list-title:hover {
  color: #ffffff !important;
}

.v-divider.divider {
  margin: 25px 40px;
  border: solid 1px #c5c5c5 !important;
}

a.social-netowrk-btn:link,
a.social-netowrk-btn:visited {
  /* padding-left: 1em !important; */
  font-family: futura-pt;
  margin-left: 12px !important;
  padding-left: 0px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
}

.Instagram-icon,
.Dribbble-icon,
.Behance-icon,
.LinkedIn-icon,
.Facebook-icon {
  background: transparent !important;
}

.v-btn::before {
  color: transparent !important;
  font-family: futura-pt;
  font-size: 24px;
}

.v-card__text span {
  margin: 5px 0;
}

.contact-city {
  font-family: futura-pt;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.contact-street {
  font-family: futura-pt;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #858282;
}

.contact-email {
  font-family: futura-pt;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3369e2;
}

.contact-tel {
  font-family: futura-pt;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3369e2;
}
@media (max-width: 600px) {
  .icon-chat {
    z-index: 6;
    top: 90%;
    right: 82%;
    color: transparent;
  }
}
@media (max-width: 475px) {
  .dark-burger {
    top: 35.7px;
    right: 25px;
  }
}
</style>
