<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      fill="#EC23C7"
      fill-rule="nonzero"
      d="M23.324 6.225a12.447 12.447 0 0 0-4.55-4.549C16.86.56 14.769 0 12.5 0c-2.267 0-4.359.559-6.274 1.676a12.444 12.444 0 0 0-4.55 4.55C.56 8.14 0 10.231 0 12.5s.559 4.359 1.676 6.274a12.448 12.448 0 0 0 4.55 4.55C8.14 24.44 10.232 25 12.5 25s4.36-.559 6.275-1.676a12.444 12.444 0 0 0 4.549-4.55C24.44 16.86 25 14.768 25 12.5s-.559-4.36-1.676-6.275zM12.5 1.823c2.691 0 5.04.89 7.048 2.67l-.204.284c-.081.114-.285.333-.61.659-.325.325-.68.635-1.066.928-.386.293-.909.626-1.57 1a15.11 15.11 0 0 1-2.117.986c-1.248-2.3-2.582-4.373-4.004-6.218.858-.206 1.699-.31 2.523-.31zM4.134 5.875a10.533 10.533 0 0 1 3.809-3.027C9.288 4.638 10.613 6.69 11.914 9c-3.298.869-6.586 1.303-9.863 1.302a10.553 10.553 0 0 1 2.083-4.427zM2.54 16.35a10.473 10.473 0 0 1-.716-3.85c0-.152.006-.266.016-.342 3.928 0 7.58-.504 10.954-1.514.348.673.635 1.275.863 1.807a1.316 1.316 0 0 1-.212.073 10.16 10.16 0 0 1-.212.057l-.602.212c-.412.152-.952.412-1.62.781a27.15 27.15 0 0 0-2.123 1.319c-.75.51-1.522 1.185-2.32 2.026a13.642 13.642 0 0 0-2.01 2.71 10.69 10.69 0 0 1-2.018-3.28zm9.961 6.827c-2.539 0-4.807-.814-6.803-2.441l.244.179c.38-.836.938-1.639 1.676-2.409.738-.77 1.449-1.392 2.133-1.864a21.01 21.01 0 0 1 2.23-1.335c.802-.417 1.35-.683 1.643-.797.293-.114.527-.203.7-.269l.033-.016h.032c1.063 2.789 1.823 5.49 2.279 8.106a10.672 10.672 0 0 1-4.167.846zm8.985-4.907a10.666 10.666 0 0 1-3.01 3.084c-.446-2.495-1.14-5.04-2.085-7.633 2.16-.337 4.379-.18 6.657.471a10.533 10.533 0 0 1-1.562 4.078zm1.53-5.916a13.253 13.253 0 0 0-.407-.074c-.163-.027-.361-.06-.595-.097a16.4 16.4 0 0 0-.78-.106 26.83 26.83 0 0 0-3.085-.179c-.38 0-.779.011-1.197.033-.417.02-.821.06-1.212.114a1.928 1.928 0 0 1-.122-.27 5.628 5.628 0 0 1-.105-.284c-.207-.456-.446-.971-.717-1.546a16.703 16.703 0 0 0 2.14-1.05c.679-.396 1.221-.749 1.628-1.058.407-.31.792-.638 1.156-.985.363-.347.599-.588.708-.724.109-.136.212-.269.31-.399l.015-.016c1.585 1.931 2.393 4.155 2.425 6.673l-.162-.032z"
    />
  </svg>
</template>

<script>
export default {
  name: "driblle"
};
</script>
