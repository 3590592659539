var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{attrs:{"id":"left-navigation"}},[(_vm.drawer)?_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"hide-overlay":"","stateless":"","mini-variant-width":"90px","app":"","touchless":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-layout',{staticClass:"text-xs-center",attrs:{"column":"","align-center":"","fill-height":""}},[_c('transition',{attrs:{"name":"boxAnimate"}},[(_vm.innerBoxAnimate)?_c('div',{ref:"innerBox",staticClass:"inner-box",style:([
              _vm.$vuetify.breakpoint.width <= '1264px'
                ? { maxHeight: '500px' }
                : ''
            ])}):_vm._e()]),_c('transition',{attrs:{"name":"boxAnimate"}},[(_vm.outerBoxAnimate)?_c('div',{ref:"outerBox",staticClass:"outer-box",style:([
              _vm.$vuetify.breakpoint.width <= '1264px'
                ? { maxHeight: '629px' }
                : ''
            ])}):_vm._e()]),_c('v-flex',[_c('LogoNoText')],1),_c('v-spacer'),_c('v-flex',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.BackArrow")])],1),_c('v-spacer')],1)],1)],1):_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('LogoNoText',{staticClass:"dark-logo-mobile"})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }