export default function winScrollPos(wScrollY) {
  return {
    data() {
      return {
        // Initialize scroll position at [x: 0, y:0]
        [wScrollY]: 0
      };
    },
    created() {
      // Only execute this code on the client side, server sticks to [0, 0]
      if (!this.$isServer) {
        this._scrollListener = () => {
          // window.pageX/YOffset is equivalent to window.scrollX/Y, but works in IE
          // round values because high-DPI devies can provide some really nasty subpixel values
          this.wScrollY = Math.round(window.pageYOffset);
        };

        // Call listener once to detect initial position
        window.addEventListener("scroll", this._scrollListener);
      }
    },
    beforeDestroy() {
      // Detach the listener when the component is gone
      window.removeEventListener("scroll", this._scrollListener);
    }
  };
}
