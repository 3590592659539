<template>
  <div
    id="single-project-mobile"
    :style="[
      $vuetify.breakpoint.width <= 959
        ? { marginBottom: '471px' }
        : { marginBottom: '575px' }
    ]"
  >
    <!-- Project header - title and information -->
    <section
      :style="{
        backgroundImage: `-webkit-linear-gradient(${
          project.titleHeaderGradiant
        })`
      }"
      id="project-header"
    >
      <v-layout row wrap align-center justify-center>
        <v-flex xs12>
          <v-card flat color="transparent">
            <v-card-title primary-title class="justify-center">
              <div>
                <h3
                  :style="{
                    backgroundImage: `-webkit-linear-gradient(${
                      project.titleGradiant
                    })`
                  }"
                  :class="[
                    $vuetify.breakpoint.width <= 1175
                      ? 'project-title-mobile'
                      : 'project-title'
                  ]"
                  class="mb-0"
                >
                  {{ project.title }}
                </h3>
                <div
                  class="pb-2"
                  :class="[
                    $vuetify.breakpoint.width <= 1175
                      ? 'project-subtitle-extended-mobile'
                      : 'project-subtitle-extended'
                  ]"
                >
                  {{ project.subtitleExtended }}
                </div>
                <div class="pb-5 project-third-extended">
                  {{ project.thirdSubtitle }}
                </div>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>

      <v-container
        :style="titleBoxSize"
        class="justify-center py-0"
        :class="isMobile ? 'fluid px-0' : ''"
      >
        <v-layout row wrap justify-star :class="isMobile ? '' : 'mx-5'">
          <v-flex
            v-for="([title, value], index) of Object.entries(
              project.projectInfo
            )"
            :key="index"
            xs6
            md3
            :class="[isMobile ? 'project-info-box-mobile' : 'project-info-box']"
          >
            <v-card flat color="transparent">
              <v-card-title>
                <div :class="[isMobile ? 'mx-3' : 'mx-auto']">
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1175
                        ? 'grey-title-mobile'
                        : 'grey-title'
                    ]"
                    class="d-block mb-2"
                    >{{ title }}</span
                  >
                  <span
                    :class="[
                      $vuetify.breakpoint.width <= 1175
                        ? 'headline-mobile'
                        : 'headline-desktop'
                    ]"
                    >{{ value }}</span
                  >
                </div>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-spacer class="hidden-sm-and-down pb-5"></v-spacer>
    </section>

    <!-- Project - two images section -->
    <section id="two-images">
      <v-card
        flat
        color="transparent"
        :style="{
          background: `linear-gradient(${project.gradiant})`
        }"
      >
        <v-layout
          row
          align-center
          justify-center
          fill-height
          class="img-container"
        >
          <v-img
            class="left-img"
            contain
            :style="[
              $vuetify.breakpoint.width <= 600
                ? { maxWidth: '254px', maxHeight: '304px' }
                : { maxWidth: '673px', maxHeight: '552px' }
            ]"
            :src="require(`@assets/images/${project.image2}`)"
          ></v-img>
          <v-img
            class="right-img"
            contain
            :style="[
              $vuetify.breakpoint.width <= 600
                ? { maxWidth: '254px', maxHeight: '304px' }
                : { maxWidth: '673px', maxHeight: '552px' }
            ]"
            :src="require(`@assets/images/${project.image1}`)"
          ></v-img>
          <v-img
            v-if="project.addittional != ''"
            class="additional-img"
            contain
            :src="require(`@assets/images/${project.addittional}`)"
          ></v-img>
        </v-layout>
      </v-card>
    </section>

    <!-- Project info section - About and challenge -->
    <section id="project-info">
      <v-card flat color="transparent">
        <v-layout
          row
          wrap
          align-start
          justify-center
          style="
           margin-top: 10em
          "
        >
          <v-flex xs11 md6 lg5>
            <v-card-title class="pb-0">
              <span
                :class="[
                  $vuetify.breakpoint.width <= 1175
                    ? 'grey-title-mobile'
                    : 'grey-title'
                ]"
                >{{ project.aboutHeader }}</span
              >
            </v-card-title>
            <v-card-text class="pt-1">
              <span class="project-top-text">{{ project.about }}</span>
            </v-card-text>
          </v-flex>
          <v-flex xs11 md6 lg5>
            <v-card-title class="pb-0">
              <span
                :class="[
                  $vuetify.breakpoint.width <= 1175
                    ? 'grey-title-mobile'
                    : 'grey-title'
                ]"
                >The Challenge</span
              >
            </v-card-title>
            <v-card-text class="pt-1">
              <span class="project-top-text">{{ project.challenge }}</span>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card>
    </section>

    <v-spacer></v-spacer>

    <!-- Project mockup section  -->
    <section id="project-mockup">
      <v-card flat color="transparent">
        <v-layout
          row
          align-center
          justify-center
          fill-height
          :style="[
            $vuetify.breakpoint.width <= 1750
              ? { width: '100%' }
              : { width: '70%', margin: '0 auto' }
          ]"
        >
          <v-img
            class="max-width-img"
            :style="[
              $vuetify.breakpoint.name === 'xs'
                ? { minHeight: '414px' }
                : { minHeight: '814px' }
            ]"
            :src="require(`@assets/images/${project.mockupBack}`)"
          >
            <v-layout row align-center justify-center fill-height>
              <v-img
                class="front-text"
                :src="require(`@assets/images/${project.mockupFront}`)"
              ></v-img>
            </v-layout>
          </v-img>
        </v-layout>
      </v-card>
    </section>
    <!-- Project Scath section  -->
    <section id="project-scath">
      <v-layout row wrap align-center justify-center>
        <v-flex xs10 md10 class="pb-5">
          <v-img
            v-if="$vuetify.breakpoint.width <= '960'"
            contain
            :src="require(`@assets/images/${project.scatchImgM}`)"
            alt="Company Icons"
          ></v-img>
          <v-img
            v-else
            contain
            :src="require(`@assets/images/${project.scatchImg}`)"
            alt="Company Icons"
          ></v-img>
        </v-flex>
      </v-layout>
    </section>
    <v-spacer></v-spacer>
    <!-- Parallax section -->
    <section id="parallax">
      <v-layout row wrap>
        <!-- <v-flex xs12>
          <v-parallax
            v-if="$vuetify.breakpoint.width <= '960'"
            :style="[
              $vuetify.breakpoint.name === 'xs'
                ? { height: '530px' }
                : { height: '606px' }
            ]"
            :src="require(`@assets/images/${project.parallaxImgM}`)"
          ></v-parallax>
            <v-parallax
             v-else
            :style="[
              $vuetify.breakpoint.name === 'xs'
                ? { height: '530px' }
                : { height: '606px' }
            ]"
            :src="require(`@assets/images/${project.parallaxImg}`)"
          ></v-parallax>
        </v-flex>-->
      </v-layout>
      <v-flex xs12>
        <v-parallax
          height="530"
          v-if="$vuetify.breakpoint.width <= '960'"
          :src="require(`@assets/images/${project.parallaxImgM}`)"
        ></v-parallax>
        <v-parallax
          v-else
          height="606"
          :src="require(`@assets/images/${project.parallaxImg}`)"
        ></v-parallax>
      </v-flex>
    </section>
    <v-spacer></v-spacer>
    <!-- Project showcase section -->
    <section id="project-showcase">
      <v-container class="max-width-custome">
        <v-layout row wrap align-start justify-center fill-height class="px-3">
          {{ setProjectShowcase() }}
          <v-flex
            d-flex
            xs6
            md3
            flat
            v-for="(item, index) in projectShowcase"
            :key="index"
            style="minWidth: '230px'; minHeight: '411px'"
          >
            <v-card flat color="transparent" class="pa-2 showcase-img">
              <v-card-title
                class="ma-auto"
                :style="[
                  $vuetify.breakpoint.name === 'xs'
                    ? { maxWidth: '174px' }
                    : { maxWidth: '230px' }
                ]"
              >
                <div class="text-xs-center">
                  <span>{{ item.title }}</span>
                </div>
              </v-card-title>
              <v-img
                class="mx-1"
                contain
                :src="require(`@assets/images/${item.imgsrc}`)"
              ></v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <v-spacer class="my-4"></v-spacer>

    <!-- Why I did what I did section -->
    <section id="why" class="container">
      <v-card
        class="why-container py-2"
        :class="[isMobile ? 'py-2' : 'py-2 pl-4 pr-5']"
      >
        <v-img
          :class="[
            isMobile ? 'why-img why-img-mobile' : 'why-img why-img-desktop'
          ]"
          :src="require(`@assets/images/${project.whyImg}`)"
        ></v-img>
        <v-card-title>
          <div class="why-title">Why I did what I did?</div>
        </v-card-title>
        <v-card-text class="why-text">{{ project.whyText }}</v-card-text>
      </v-card>
    </section>

    <v-spacer class="my-5"></v-spacer>

    <!-- Project testimony section - 9 phones -->
    <section id="client-testimony">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 lg7 offset-lg1>
            <v-card flat class="ma-auto transparent">
              <v-img
                v-if="`${project.type}` == 'desktop'"
                :style="[
                  $vuetify.breakpoint.name === 'xs'
                    ? { height: '709px' }
                    : { maxHeight: '869px', backgroundSize: 'contain' }
                ]"
                :src="require(`@assets/images/${project.ninePhones}`)"
              ></v-img>
              <BouncingSmartPhones
                :project="project"
                v-if="`${project.type}` == 'mobile'"
                :style="[
                  $vuetify.breakpoint.name === 'xs'
                    ? { height: '709px' }
                    : { maxHeight: '869px' }
                ]"
              />
            </v-card>
          </v-flex>
          <v-flex xs12 lg4>
            <v-card flat class="ma-auto transparent">
              <v-card-text>
                <blockquote
                  :class="[
                    $vuetify.breakpoint.width <= '1263'
                      ? 'testimony-mobile'
                      : 'testimony-desktop'
                  ]"
                  :style="[
                    $vuetify.breakpoint.width <= '1499'
                      ? { fontSize: '45px' }
                      : { fontSize: '56px' }
                  ]"
                >
                  <TypewriterTestimony
                    :project="project"
                    :hasFinishedRendering="this.hasFinishedRendering"
                  />
                </blockquote>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <v-spacer class="my-2"></v-spacer>
    <!-- Project showcase2 section -->
    <section id="project-showcase-2">
      <v-container class="max-width-custome">
        <v-layout
          row
          wrap
          align-start
          justify-center
          fill-height
          class="pt-5 px-3"
        >
          <v-flex
            d-flex
            xs6
            md3
            flat
            v-for="(item, index) in project.projectShowCase2.slice(0, 4)"
            :key="index"
            min-width="230px"
          >
            <v-card flat color="transparent" class="pa-2 showcase-img-2">
              <v-card-title
                class="ma-auto"
                :style="[
                  $vuetify.breakpoint.name === 'xs'
                    ? { maxWidth: '174px' }
                    : { maxWidth: '230px' }
                ]"
              >
                <div class="text-xs-center">
                  <span>{{ item.title }}</span>
                </div>
              </v-card-title>
              <v-layout row align-center justify-center>
                <v-img
                  class="mx-1"
                  contain
                  :src="require(`@assets/images/${item.imgSrc}`)"
                ></v-img>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout
          row
          wrap
          align-start
          justify-center
          fill-height
          class="pb-5 px-3"
        >
          <v-flex
            d-flex
            xs6
            md2
            flat
            v-for="(item, index) in project.projectShowCase2.slice(4, 8)"
            :key="index"
          >
            <v-card flat color="transparent" class="pa-2 showcase-img-2">
              <v-card-title
                class="ma-auto"
                :style="[
                  $vuetify.breakpoint.name === 'xs'
                    ? { maxWidth: '174px' }
                    : { maxWidth: '230px' }
                ]"
              >
                <div class="text-xs-center">
                  <span>{{ item.title }}</span>
                </div>
              </v-card-title>
              <v-layout row align-center justify-center>
                <v-img
                  mx-1
                  contain
                  :src="require(`@assets/images/${item.imgSrc}`)"
                ></v-img>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <!-- Next project section -->
    <section id="next-project">
      <v-container>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 md5 lg5 xl5>
            <v-card flat class="transparent">
              <v-card-text class="py-0 my-0">
                <div class="next-project d-block">Next Project</div>
              </v-card-text>
              <v-card-title>
                <h3
                  :style="{
                    backgroundImage: `-webkit-linear-gradient(${
                      nextProject.titleGradiant
                    })`
                  }"
                  class="next-project-title d-block mt-0"
                  :class="[
                    $vuetify.breakpoint.width <= 1175
                      ? 'project-title-mobile-next'
                      : 'project-title'
                  ]"
                >
                  {{ nextProject.title }}
                </h3>
              </v-card-title>
              <v-card-text class="py-0 my-0">
                <span class="next-project-category">{{
                  nextProject.projectInfo.Type
                }}</span>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs8 md5 lg7 xl5 offset-xs4 offset-md0>
            <v-card flat class="transparent">
              <v-card-text
                class="next-project-subtitle next-project-subtitle text-xs-right"
              >
                <div
                  :style="[
                    isMobile ? { fontSize: '21px' } : { fontSize: '28px' }
                  ]"
                >
                  {{ nextProject.subtitleExtended }}
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex
            xs10
            md10
            lg12
            xl10
            class="mt-5"
            @mouseover="mouseHandler"
            @mouseleave="mouseHandler"
          >
            <router-link :to="`/projects/${nextProject.title}`">
              <div
                :style="[
                  isMobile
                    ? {
                        position: 'relative',
                        marginBottom: '40px'
                      }
                    : { marginBottom: '40px', overflow: 'hidden' }
                ]"
              >
                <v-card
                  flat
                  :style="{
                    background: `linear-gradient(${nextProject.gradiant})`
                  }"
                  style="paddingTop: 40px"
                >
                  <v-img
                    contain
                    :src="
                      require(`@assets/images/${nextProject.nextProjectImg}`)
                    "
                    class="mx-5 my-0 next-project-img"
                    :style="[
                      isMobile
                        ? ''
                        : { paddingLeft: '150px', paddingRight: '150px' }
                    ]"
                  ></v-img>
                </v-card>
                <span
                  v-if="isMobile"
                  class="big-btn-arrow"
                  :style="{
                    background: `url(@assets/images/${
                      nextProject.arrow
                    }) center center, linear-gradient(${nextProject.gradiant})`
                  }"
                >
                  <v-img
                    class="arrow"
                    :src="require(`@assets/images/${nextProject.arrow}`)"
                  ></v-img>
                </span>
              </div>
            </router-link>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </div>
</template>

<script>
import WinScrollPos from "@src/mixins/_window-scroll-position";
import BouncingSmartPhones from "@components/_bouncingSmartPhones.vue";
import TypewriterTestimony from "@components/_typewriterTestimony.vue";
import { ObserveVisibility } from "vue-observe-visibility";

import { setTimeout } from "timers";
export default {
  mixins: [WinScrollPos("wScrollY")],
  props: ["project", "nextProject"],
  components: {
    BouncingSmartPhones,
    TypewriterTestimony
  },
  data() {
    return {
      isMobile: false,
      projectShowcase: {},
      hasFinishedRendering: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("scroll", this.scrollHandler);

      //Init
      this.getWindowWidth();
      this.setProjectShowcase();

      // hack to delay "component visibility change" on render of all components
      setTimeout(() => {
        this.hasFinishedRendering = true;
      }, 500);
    });
  },
  computed: {
    titleBoxSize() {
      if (this.$vuetify.breakpoint.width >= 1904) {
        return { width: "85% !important", margin: "0 auto !important" };
      } else if (this.$vuetify.breakpoint.width <= 1263) {
        return {
          padding: "0px !important",
          margin: "0px auto !important",
          maxWidth: "1100px"
        };
      } else {
        return { margin: "" };
      }
    }
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 959) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setProjectShowcase() {
      if (this.isMobile) {
        this.projectShowcase = this.project.projectShowcaseMobile;
      } else {
        this.projectShowcase = this.project.projectShowcaseDesktop;
      }
    },
    mouseHandler(e) {
      // Next project section animation
      let nextProjectSubtitle = document.querySelector(
        ".next-project-subtitle"
      );
      let nextProjectImg = document.querySelector(".next-project-img");

      if (!this.isMobile) {
        if (e.type === "mouseover") {
          nextProjectSubtitle.style.opacity = 1;
          nextProjectImg.style.transform = "scale(1)";
        } else {
          nextProjectSubtitle.style.opacity = 0.7;
          nextProjectImg.style.transform = "scale(1.05)";
        }
      }
    },
    scrollHandler() {
      // console.log("handling scroll event");
      let H = window.innerHeight;
      let showcase = document.querySelector("#project-showcase");
      let showcaseImg = document.querySelectorAll(".showcase-img");
      let mockup = document.querySelector("#project-mockup");
      let showcase2 = document.querySelector("#project-showcase-2");
      let showcaseImg2 = document.querySelectorAll(".showcase-img-2");

      // Animation for project showcase section
      // eslint-disable-next-line prettier/prettier
      if (this.wScrollY > showcase.offsetTop - (H / 1.2)) {
        // console.log("time to animate project showcase section");
        showcaseImg.forEach((element, index) => {
          setTimeout(() => {
            element.classList.add("is-showing");
          });
        });
      } else {
        for (let i = 0; i < showcaseImg.length; i++) {
          showcaseImg[i].classList.remove("is-showing");
        }
      }

      // Animation mockup section
      let frontText = document.querySelector(".front-text");
      if (this.wScrollY > mockup.offsetTop - 600) {
        const pos = this.wScrollY - mockup.offsetTop;
        frontText.style.transform = `translatey(${1 - pos}px)`;
      }

      // Animation for project showcase 2
      // eslint-disable-next-line prettier/prettier
      if (this.wScrollY > showcase2.offsetTop - (H / 2)) {
        showcaseImg2.forEach((element, index) => {
          setTimeout(() => {
            element.classList.add("is-showing");
          });
        });
      } else {
        for (let i = 0; i < showcaseImg2.length; i++) {
          showcaseImg2[i].classList.remove("is-showing");
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("scroll", this.scrollHandler);
  }
};
</script>

<style scoped lang="scss">
.max-width-img {
  max-width: 1162px;
}
#single-project-mobile {
  position: relative;
  z-index: 2;
}
.max-width-custome {
  max-width: 1200px !important;
}
/* Project Header section */
.project-title {
  font-family: "futura-pt", sans-serif;
  font-size: 60px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.44px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-title-mobile {
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.project-title-mobile-next {
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.96px;
  text-align: left;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-subtitle-extended {
  font-size: 30px;
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.72px;
  text-align: center;
}

.project-subtitle-extended-mobile {
  font-size: 22px;
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  text-align: center;
}

.project-third-extended {
  font-family: "futura-pt", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  text-align: center;
  color: #727272;
}

.project-info-box {
  border-right: 1px solid #262626;
}

.project-info-box:last-child {
  border-right: 0px;
}

.project-info-box-mobile {
  border-top: 1px solid #262626;
  border-right: 1px solid #262626;
}

.project-info-box-mobile:nth-child(even) {
  border-right: 0px;
}

.grey-title {
  font-family: "Futura", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: #727272;
  /* min-width: 210px; */
}

.project-top-text {
  font-family: Futura-pt;
  font-size: 24px !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: -0.5px;
  color: #ffffff;
}

.grey-title-mobile {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  color: #727272;
}

.headline-desktop {
  font-family: "Futura", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.58px;
  color: #ffffff;
}

.headline-mobile {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.43px;
  color: #ffffff;
  white-space: nowrap;
}

@media (max-width: 750px) {
  .project-title-mobile {
    margin-top: 1.5em;
  }
}

@media (max-width: 600px) {
  .project-title-mobile {
    margin-top: 2em;
  }
}

/* Two phones section */
.layout.img-container {
  padding: 7.5em 0 3.5em 0;
  width: 50%;
  margin: 0 auto;
  position: relative;
  min-height: 480px;
}

@media (min-width: 625px) {
  .layout.img-container {
    min-height: 550px;
  }
}

@media (min-width: 725px) {
  .layout.img-container {
    min-height: 650px;
  }
}

@media (min-width: 825px) {
  .layout.img-container {
    min-height: 750px;
  }
}

@media (min-width: 925px) {
  .layout.img-container {
    min-height: 955px;
  }
}

.left-img {
  position: absolute;
  z-index: 2;
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(-15%);
}

.right-img {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateX(25%);
}

.additional-img {
  position: absolute;
  z-index: 1 !important;
  max-width: 1335px;
  top: 10px;
  left: 0;
  right: 0;
  bottom: -102%;
  margin: 0 -6em;
}

.v-card__text > span {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.43px;
}

/* Project mockup section */

.front-text {
  transform: translateY(400px);
  transition: 0.05s;
}

.spacer {
  height: 5em;
}

img.v-parallax__image {
  width: 140%;
}

/* Project Showcase sections */

.showcase-img {
  transition: all 1.5s ease-in-out;
  transform: translateY(350px);
  opacity: 0;

  &.is-showing {
    opacity: 1;
    transform: translateY(0);
  }
}

.showcase-img-2 {
  transition: all 1.5s ease-in-out;
  transform: translateY(350px);
  opacity: 0;

  &.is-showing {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .showcaseAnim-enter-active {
  animation: slideUp 5 ease-in-out top;
}

.showcaseAnim-leave-active {
  animation: slideDown 5 ease-in-out bottom;
} */

/* @keyframes slideUp {
  from {
    transform: translate(0, 500px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideDown {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 500px);
  }
} */

/* Why I did what I did section */

.why-img {
  position: absolute;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  max-width: 210px;
}

.why-img-desktop {
  width: 20%;
  transform: translate(0%, -200%);
}

.why-img-mobile {
  width: 50%;
  transform: translate(50%, -150%);
}

.why-title {
  font-family: Futura-pt;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.62px;
  color: #727272;
}

.why-container {
  position: relative;
  border-radius: 43px;
  border: solid 1px #292929;
  background: #0b0b0b !important;
  margin: 0 auto;
  max-width: 1100px;
}

.why-text {
  font-family: Futura-pt;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #ffffff;
}

/* Client testimony section */

blockquote.testimony-mobile {
  margin-top: 0%;
}

blockquote.testimony-desktop {
  margin-left: -15%;
  margin-top: 0%;
}

/* next project section */
.next-project {
  opacity: 0.48;
  font-family: Futura-pt;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.75px;
  color: #ffffff;
}

.next-project-title {
  font-size: 52px !important;
}

.next-project-category {
  border: solid 0.5px;
  padding: 8px 11px;
  border-radius: 15.5px;
  border-color: darkslategrey;
  font-size: 13px;
}

.next-project-subtitle {
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.next-project-img {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
  max-height: 301px;
}

.big-btn-arrow {
  width: 64px;
  height: 64px;
  border: solid 6px #0b0b0b;
  border-radius: 50% !important;
  box-sizing: content-box;
  position: absolute;
  right: -10px;
  bottom: -30px;
}

.arrow {
  width: 12px;
  height: 21px;
  margin: 20px auto;
}
</style>
