<template>
  <div class="footer">
    <v-img :max-height="footerHeight" absolute :src="footerImageSrc">
      <!-- <v-img :src="footerGradiantSrc"> -->
      <v-layout align-center justify-center row>
        <v-flex
          xs12
          class="footer-text footer-text-1 text-xs-center"
          :style="[
            $vuetify.breakpoint.name === 'xs' ? '' : { paddingTop: '1.75em' }
          ]"
          >Do you have a question or</v-flex
        >
      </v-layout>
      <v-layout align-center justify-center row>
        <v-flex
          xs12
          class="footer-text footer-text-2 text-xs-center"
          :style="[
            $vuetify.breakpoint.name === 'xs' ? '' : { marginBottom: '0.75em' }
          ]"
        >
          anything else?
          <router-link to="/contact">
            <span
              :class="[
                $vuetify.breakpoint.name === 'xs'
                  ? 'outline-text-mobile'
                  : 'outline-text'
              ]"
              >Contact me</span
            >
          </router-link>
        </v-flex>
      </v-layout>

      <!-- Social links... data are props passed from Home.vue -->
      <v-layout row wrap align-center justify-center container>
        <v-flex class="text-xs-center mx-0 icon-box">
          <v-hover v-for="(link, index) in socialLinks" :key="index">
            <v-btn
              slot-scope="{ hover }"
              @mouseover="hover = true"
              @mouseleave="hover = null"
              flat
              class="text-none social-netowrk-btn"
              :href="link.url"
              target="_blank"
              :class="{ [`${link.name}-icon`]: hover }"
            >
              <v-icon
                class="mr-1 hidden-xs-only"
                :style="{
                  visibility: hover ? 'visible' : 'hidden'
                }"
                >{{ link.icon }}</v-icon
              >
              {{ link.name }}
            </v-btn>
          </v-hover>
        </v-flex>
      </v-layout>
    </v-img>
    <v-footer class="text-xs-center mb-2" absolute color="transparent">
      <div class="copyright-text" style="width: 100%">
        All rights reserved Itay Blayer
      </div>
    </v-footer>
  </div>
</template>

<script>
import { eventBus } from "@src/main";
export default {
  data: () => ({
    socialLinks: [],
    hover: null,
    footerImage: require("@assets/images/footer-img-01.png"),
    footerImageMobile: require("@assets/images/footer-img-mobile-01.png"),
    footerGradiant: require("@assets/images/footer-gradiant.png"),
    footerGradiantMobile: require("@assets/images/footer-gradiant-mobile.png")
  }),
  computed: {
    footerImageSrc() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return this.footerImage;
        case "lg":
          return this.footerImage;
        case "md":
          return this.footerImage;
        default:
          return this.footerImageMobile;
      }
    },
    footerGradiantSrc() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return this.footerGradiant;
        case "lg":
          return this.footerGradiant;
        case "md":
          return this.footerGradiant;
        default:
          return this.footerGradiantMobile;
      }
    },
    footerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 472;
        case "sm":
          return 472;
        default:
          return 577;
      }
    }
  },
  created() {
    // listen to event bus to get social links data from side nav component
    eventBus.$on("social", data => {
      this.socialLinks = data;
    });
  }
};
</script>

<style scoped>
.footer {
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 1;
}

.outline-text {
  -webkit-text-stroke: 2px #fff;
  font-family: "futura-pt", sans-serif;
  font-size: 55px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  transition: all 0.3s;
  /* margin-left: 53px; */
  color: rgba(255, 255, 255, 0);
}
.outline-text:hover {
  -webkit-text-stroke: 2px #fff;
  font-family: "futura-pt", sans-serif;
  font-size: 55px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #fff;
  transition: all 0.3s;
  /* border-bottom: 1px solid #fff; */ /* removed at client's request */
}

.outline-text-mobile {
  display: block;
  margin-top: 16px;
  -webkit-text-stroke: 2px #ffffff;
  font-family: "futura-pt", sans-serif;
  font-size: 45px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0);
  border-bottom: 2px solid white;
  width: 230px;
}

.footer-text {
  font-family: "futura-pt", sans-serif;
  font-size: 55px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #ffffff;
  transition: all 0.5s;
}
@media (max-width: 680px) {
  .footer-text {
    font-size: 34px;
    line-height: 1.35;
  }
  .footer-text-1 {
    padding-top: 100px !important;
  }
}
@media (max-width: 600px) {
  .footer-text-1 {
    padding-top: 120px;
    text-align: left !important;
    font-family: "futura-pt", sans-serif;
    font-size: 34px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    color: #ffffff;
    padding-left: 20px;
  }
  .footer-text-2 {
    text-align: left !important;
    padding-left: 20px;
  }

  .icon-box {
    width: 100%;
    text-align: left !important;
    margin-top: 20px;
  }
}
</style>
