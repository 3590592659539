<template>
  <v-app dark>
    <v-content>
      <transition name="fade">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  // data() {
  //   return {
  //     cursorPointerImg: require("@assets/images/cursor-pointer-v3.png"),
  //   };
  // },
  // methods: {
  //   updateMouseCursor() {
  //     var elms = document.getElementsByTagName("*");
  //     var n = elms.length;
  //     for(var i = 0; i < n; i ++) {
  //       if(window.getComputedStyle(elms[i]).cursor == "pointer") {
  //         elms[i].style.cursor = `url(${this.cursorPointerImg}), pointer`;
  //       }
  //     }
  //   }
  // },
  // mounted() {
  //   this.updateMouseCursor()
  // },
  // updated() {
  //   this.updateMouseCursor()
  // }
};
</script>

<style>
.v-content__wrap {
  background: #0b0b0b;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.4s;
}
.fade-enter-active {
  transition-delay: 0.4s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
