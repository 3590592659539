<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <g fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 44 0)">
      <circle cx="22" cy="22" r="22" fill="#3369e2" />
      <path stroke="#fff" stroke-width="2" d="M26 14l-8 8 8 8" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "right-arrow"
};
</script>
