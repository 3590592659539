import { render, staticRenderFns } from "./_singleProjectMobile.vue?vue&type=template&id=416e8735&scoped=true&"
import script from "./_singleProjectMobile.vue?vue&type=script&lang=js&"
export * from "./_singleProjectMobile.vue?vue&type=script&lang=js&"
import style0 from "./_singleProjectMobile.vue?vue&type=style&index=0&id=416e8735&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416e8735",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VContainer,VFlex,VImg,VLayout,VParallax,VSpacer})
