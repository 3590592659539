<template>
  <div id="scroll-bar">
    <div class="progress-container">
      <div class="progress-bar"></div>
    </div>
  </div>
</template>

<script>
import WinScrollPos from "@src/mixins/_window-scroll-position";
export default {
  mixins: [WinScrollPos("wScrollY")], //position is an array...  x = position[0]; y = position[1]
  methods: {
    scrollEffect() {
      // set scroll location on the page
      const winScroll = this.wScrollY;
      // document.body.scrollTop || document.documentElement.scrollTop;

      // set page height
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      // Convert scroll bar height in percentage
      const scrolled = (winScroll / height) * 100;

      // apply scrolled percentage to progress bar height
      const progressBar = document.querySelector(".progress-bar");
      progressBar.style.height = `${scrolled}%`;

      // set scroll bar opacity to 1 after scrolling to 20% of the page
      const scrollBar = document.querySelector("#scroll-bar");
      if (scrolled >= 5 && scrolled < 95) {
        scrollBar.style.opacity = 1;
      } else {
        scrollBar.style.opacity = 0;
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollEffect();
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {
      this.scrollEffect();
    });
  }
};
</script>

<style scoped>
#scroll-bar {
  position: fixed;
  top: 25%;
  right: 2%;
  bottom: 20%;
  z-index: 5;
  opacity: 0;
  transition: 2s ease-in-out;
}

.progress-container {
  height: 100%;
  background: #262626;
  width: 4px;
  border-radius: 50px;
}

.progress-bar {
  background: #cccccc;
  width: 4px;
  height: 0;
  transition: 0.5s;
  max-height: 100%;
  border-radius: 50px;
}
</style>
