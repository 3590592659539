<template>
  <div class="header-section">
    <!-- <v-img aspect-ratio="2.84" :src="imgSrc">
      <v-img :src="darknessImgSrc" class="pt-5"></v-img>
    </v-img>-->

    <header
      class="v-header container"
      :class="{ mobile: $vuetify.breakpoint.width <= 767 }"
    >
      <div
        class="video-container fullscreen-video-wrap"
        :class="{ mobile: $vuetify.breakpoint.width <= 767 }"
      >
        <video
          :poster="videoPoster"
          autoplay
          playsinline
          loop
          muted
          :style="{
            height: $vuetify.breakpoint.width <= 800 ? '100%' : 'auto'
          }"
        >
          <source :src="videoUrl" />
        </video>
      </div>
      <div
        class="header-overlay"
        :class="{ mobile: $vuetify.breakpoint.width <= 767 }"
      ></div>
      <v-layout wrap align-center justify-end column class="buttons">
        <v-card
          flat
          depressed
          color="transparent"
          class="labels-container dark-itay-blayer text-xs-center"
        >
          <!-- <h1
            class="font-weight-bold"
            :style="{
              fontSize: $vuetify.breakpoint.width <= 767 ? '45px' : '65px'
            }"
          >
            Itay Blayer
          </h1>-->
          <h1 class="main-header-text pb-2">I’m Itay Blayer</h1>
          <!--
            2019-10-12
            This section below needs to be re done into one H2, For now added extra space
            to fix an issue on mobile when both spans showing on the same line resulting in
            ... organizationsto ...
          -->
          <h2 class="mx-1 pb-2 sub-header-text">
            <span class="sub-header-text-break-1"
              >I’m an experienced product designer</span
            >
            <span class="sub-header-text-break-2">
              with attention to flexibility, speed, and quality</span
            >
          </h2>
        </v-card>
        <div class="buttons-container text-none py-4">
          <router-link to="/about">
            <v-btn large round flat outline class="text-none" max-width="175px"
              >More about me</v-btn
            >
          </router-link>
          <router-link to="/contact">
            <v-btn
              large
              round
              dark
              color="#3369e2"
              class="text-none"
              max-width="224px"
              >Shoot me a message</v-btn
            >
          </router-link>
        </div>
      </v-layout>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoUrl: require("@assets/video/video-bg.mp4"),
      videoPoster: require("@assets/video/poster.png"),
      drawer: null,
      heroImg: require("@assets/images/dark-hero.jpg"),
      darknessGradiant: require("@assets/images/dark-darknessGradiant.png"),
      heroImgMobile: require("@assets/images/dark-hero-m.png"),
      darknessGradiantMobile: require("@assets/images/dark-darknessGradiant-m.png")
    };
  },
  computed: {
    imgSrc() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return this.heroImg;
        case "lg":
          return this.heroImg;
        case "md":
          return this.heroImg;
        // case "sm":
        //   return this.heroImg;
        default:
          return this.heroImgMobile;
      }
    },
    darknessImgSrc() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return this.darknessGradiant;
        case "lg":
          return this.darknessGradiant;
        case "md":
          return this.darknessGradiant;
        // case "sm":
        //   return this.darknessGradiant;
        default:
          return this.darknessGradiantMobile;
      }
    }
  }
};
</script>

<style scoped>
.header-section {
  position: relative;
}

.dark-itay-blayer {
  font-family: "futura-pt", sans-serif;
  width: 100%;
  margin-top: -10%;
}

.dark-itay-blayer > h1 {
  font-size: 65px;
}

.dark-itay-blayer > h2 {
  font-size: 22px;
  font-weight: 500;
}

.buttons {
  margin-top: -10px;
  position: relative;
}

button.v-btn {
  /* width: 224px; */
  /* height: 54px; */
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: -0.43px;
  text-align: center;
  color: #ffffff;
  padding: 0 14px;
}

.layout {
  height: 100%;
}

button.v-btn--flat {
  border-color: #3369e2;
}

.video-container {
  z-index: 5;
}

.labels-container,
.buttons-container {
  z-index: 5;
}

/* video background header */
/* ====================== */
.v-header {
  height: 80vh;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.container {
  max-width: 960px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center;
}

.fullscreen-video-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.fullscreen-video-wrap video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: fill;
}

.header-overlay {
  height: 80.1vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 5;
  background: linear-gradient(
    to bottom,
    rgba(11, 11, 11, 0.2),
    rgba(11, 11, 11, 0.65) 57%,
    #000000 100%
  );
}
.job-search {
  font-family: Futura-pt;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  width: 345px;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background-color: #ffffff25;
  padding: 2px;
  margin: 0 auto;
}
.main-header-text {
  opacity: 0.48;
  font-family: "futura-pt", sans-serif;
  font-size: 18px !important;
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.sub-header-text {
  font-family: "futura-pt", sans-serif;
  font-size: 34px !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: -0.82px;
  text-align: center;
  color: #ffffff;
}
.sub-header-text-break-1 {
  display: block;
}
.sub-header-text-break-2 {
  display: block;
}

.mobile {
  height: 100vh !important;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-video-wrap video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-video-wrap video {
    width: auto;
    height: auto;
  }
}
@media only screen and (max-width: 600px) {
  .job-search {
    font-size: 18px;
    width: 330px;
    padding: 2px;
  }
  .sub-header-text-break-1 {
    display: inline;
  }
  .sub-header-text-break-2 {
    display: inline;
  }
}
</style>
