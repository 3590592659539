<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      fill="#0077B7"
      fill-rule="nonzero"
      d="M22.739 0H2.26A2.261 2.261 0 0 0 0 2.261v20.477A2.261 2.261 0 0 0 2.261 25h20.477A2.261 2.261 0 0 0 25 22.738V2.261A2.261 2.261 0 0 0 22.739 0zM7.736 21.587a.658.658 0 0 1-.658.658H4.277a.658.658 0 0 1-.659-.658V9.843c0-.363.295-.658.659-.658h2.8c.364 0 .66.295.66.658v11.744zM5.677 8.078a2.661 2.661 0 1 1 0-5.323 2.661 2.661 0 0 1 0 5.323zm16.7 13.562c0 .334-.272.605-.606.605h-3.006a.605.605 0 0 1-.605-.605V16.13c0-.821.241-3.6-2.148-3.6-1.852 0-2.228 1.902-2.303 2.755v6.354c0 .334-.271.605-.606.605h-2.907a.605.605 0 0 1-.605-.605V9.79c0-.334.27-.605.605-.605h2.907c.335 0 .606.271.606.605v1.025c.687-1.031 1.707-1.827 3.881-1.827 4.814 0 4.786 4.497 4.786 6.968v5.684z"
    />
  </svg>
</template>

<script>
export default {
  name: "linkedin"
};
</script>
