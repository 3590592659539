<template>
  <div>
    <p>{{ this.project.testimony }}</p>
    <footer>
      <span
        :class="[
          this.hasTypewriterEffectFinished ? 'client-name' : 'client-name hidden'
        ]"
        :style="[
          $vuetify.breakpoint.width <= '1499'
            ? { fontSize: '18px' }
            : { fontSize: '24px' }
        ]"
      >{{ project.clientName }}</span>
      <div
        :class="[
          this.hasTypewriterEffectFinished ? 'client-role' : 'client-role hidden'
        ]"
        :style="[
          $vuetify.breakpoint.width <= '1499'
            ? { fontSize: '14px' }
            : { fontSize: '16px' }
        ]"
      >{{ project.clientRole }}</div>
    </footer>
  </div>
</template>

<script>
import { ObserveVisibility } from "vue-observe-visibility";

export default {
  data() {
    return {
      typewriterTestimony: '',
      tempTypewriterTestimony: this.project.testimony,
      typewriterTestimonyIndex: 0,
      hasStartedTypewriterEffect: true,
      hasTypewriterEffectFinished: true
    };
  },
  props: ["project", "hasFinishedRendering"],
  components: {
  },
  directives: {
    ObserveVisibility
  },
  methods: {
    testimonyVisibilityChanged() {
      if (this.hasFinishedRendering === false) {
        return;
      }
      if (this.hasStartedTypewriterEffect === true) {
        return;
      }

      this.hasStartedTypewriterEffect = true;
      this.startTypewriterEffect();
    },
    startTypewriterEffect() {
      // console.log("props: " + this.project);
      if (this.typewriterTestimonyIndex > this.tempTypewriterTestimony.length) {
        this.handleTypewriterEffectComplete();
      } else {
        this.typewriterTestimony = this.tempTypewriterTestimony.substring(0, this.typewriterTestimonyIndex);
        this.typewriterTestimonyIndex += 1;
        setTimeout(() => {
          this.startTypewriterEffect();
        }, 60);
      }
    },
    handleTypewriterEffectComplete() {
      this.hasTypewriterEffectFinished = true;
    }
  }
};
</script>

<style scoped>
#client-testimony {
  //background: linear-gradient(294deg, #292929, #131313);
  margin-bottom: 15em;
  z-index: 3;
}

.testimony {
  position: relative;
  /* font-size: 45px; */
  font-weight: normal;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: #ffffff;
}

.testimony::before {
  content: open-quote;
  font-size: 55px;
  font-family: Arial;
  font-weight: bold;
  color: #5c5c5c;
  position: absolute;
  top: -0.45em;
  left: -0.5em;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.testimony::after {
  content: close-quote;
  font-size: 55px;
  font-family: Arial;
  font-weight: bold;
  color: #5c5c5c;
  position: absolute;
  bottom: -0.75em;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

.client-name {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.43px;
}

.client-name::before {
  content: "";
  height: 1px;
  display: inline-block;
  background: #ffffff;
  opacity: 0.42;
  width: 97px;
  margin-right: 22px;
  margin-bottom: 5px;
}

.client-role {
  opacity: 0.42;
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.34px;
  margin-left: 119px;
}

.hidden {
  visibility: hidden;
}
</style>
