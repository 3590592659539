import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";

// create an event bus to pass data between components
export const eventBus = new Vue();

// Custom icons
import DarkBurger from "@components/icons/_dark-burger.vue";
import RightArrow from "@components/icons/_right-arrow.vue";
import LeftArrow from "@components/icons/_left-arrow.vue";
import BackArrow from "@components/icons/_backArrow.vue";
import Instagram from "@components/icons/social/_instagram.vue";
import Dribbble from "@components/icons/social/_dribbble.vue";
import Facebook from "@components/icons/social/_facebook.vue";
import Behance from "@components/icons/social/_behance.vue";
import Linkedin from "@components/icons/social/_linkedin.vue";
import Close from "@components/icons/_dark-close.vue";
import GoUp from "@components/icons/_goUpIcon.vue";
import RegularLine from "@components/icons/_regular-line.vue";

import("@assets/main.css");
import("./stylus/main.styl");

Vue.config.productionTip = false;
Vue.use(Vuetify, {
  icons: {
    burger: {
      component: DarkBurger,
      name: "burger"
    },
    RegularLine: {
      component: RegularLine,
      name: "regular-line"
    },
    rightArrow: {
      component: RightArrow,
      name: "right-arrow"
    },
    leftArrow: {
      component: LeftArrow,
      name: "left-arrow"
    },
    Instagram: {
      component: Instagram,
      name: "instagram"
    },
    Dribbble: {
      component: Dribbble,
      name: "Dribbble"
    },
    Behance: {
      component: Behance,
      name: "behancd"
    },
    Linkedin: {
      component: Linkedin,
      name: "linkedin"
    },
    Facebook: {
      component: Facebook,
      name: "facebook"
    },
    BackArrow: {
      component: BackArrow,
      name: "back-arrow"
    },
    Close: {
      component: Close,
      name: "close"
    },
    GoUp: {
      component: GoUp,
      name: "go-up"
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
