var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"projects"}},[[_c('v-container',{attrs:{"grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":"","justify-center":""}}),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{class:[
            _vm.$vuetify.breakpoint.name === 'xs' ? 'py-3' : 'pa-3 flex-item' ],attrs:{"xs12":"","md6":""}},[_c('router-link',{attrs:{"to":"/projects/dudas-new-animations","exact":""}},[_c('div',{on:{"mouseover":_vm.handleCardMouseOver,"mousemove":_vm.handleCardMouseMove,"mouseout":_vm.handleCardMouseOut}},[_c('v-card',{class:[
                  _vm.$vuetify.breakpoint.name === 'lg' ||
                  _vm.$vuetify.breakpoint.name === 'xl'
                    ? 'ma-auto rotatable'
                    : 'ma-auto non-rotatable' ],staticStyle:{"background-color":"transparent"},attrs:{"flat":"","dark":""}},[_c('div',{style:({
                    background: "linear-gradient(96deg, #f66035 4%, #ff3300)",
                    pointerEvents: 'none',
                  })},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"card-title text-xs-center"},[_c('h1',{staticClass:"project-title pb-3"},[_vm._v(" Duda’s new animations ")])])]),_c('v-img',{class:{ wide: true },attrs:{"aspect-ratio":"1.25","src":_vm.animationsImg,"contain":""}})],1)])],1)])],1),_c('v-flex',{class:[
            _vm.$vuetify.breakpoint.name === 'xs' ? 'py-3' : 'pa-3 flex-item' ],attrs:{"xs12":"","md6":""}},[_c('router-link',{attrs:{"to":"/projects/dudas-new-text-editor","exact":""}},[_c('div',{on:{"mouseover":_vm.handleCardMouseOver,"mousemove":_vm.handleCardMouseMove,"mouseout":_vm.handleCardMouseOut}},[_c('v-card',{class:[
                  _vm.$vuetify.breakpoint.name === 'lg' ||
                  _vm.$vuetify.breakpoint.name === 'xl'
                    ? 'ma-auto rotatable'
                    : 'ma-auto non-rotatable' ],staticStyle:{"background-color":"transparent"},attrs:{"flat":"","dark":""}},[_c('div',{style:({
                    background: "linear-gradient(96deg, #f66035 4%, #ff3300)",
                    pointerEvents: 'none',
                  })},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"card-title text-xs-center"},[_c('h1',{staticClass:"project-title pb-3"},[_vm._v(" Duda’s new text editor ")])])]),_c('v-img',{class:{ wide: true },attrs:{"aspect-ratio":"1.25","src":_vm.textEditor22,"contain":""}})],1)])],1)])],1),_vm._l((_vm.projects),function(project){return _c('v-flex',{key:project.id,class:[
            _vm.$vuetify.breakpoint.name === 'xs' ? 'py-3' : 'pa-3 flex-item' ],attrs:{"xs12":"","md6":""}},[_c('router-link',{attrs:{"to":project.private ? '' : ("/projects/" + (project.title)),"exact":""}},[_c('div',{on:{"mouseover":_vm.handleCardMouseOver,"mousemove":_vm.handleCardMouseMove,"mouseout":_vm.handleCardMouseOut}},[_c('v-card',{class:[
                  _vm.$vuetify.breakpoint.name === 'lg' ||
                  _vm.$vuetify.breakpoint.name === 'xl'
                    ? 'ma-auto rotatable'
                    : 'ma-auto non-rotatable' ],staticStyle:{"background-color":"transparent"},attrs:{"flat":"","dark":""}},[_c('div',{style:({
                    background: ("linear-gradient(" + (project.gradiant) + ")"),
                    pointerEvents: 'none',
                  })},[(project.private)?_c('div',{staticClass:"text-xs-center private-chip"},[_c('v-img',{attrs:{"contain":"","src":_vm.privateSrc}})],1):_vm._e(),_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"card-title text-xs-center"},[_c('h1',{staticClass:"project-title pb-3"},[_vm._v(_vm._s(project.title))])])]),_c('v-img',{class:{ wide: project.wide },attrs:{"aspect-ratio":"1.25","src":require(("@assets/images/projects/" + (project.imgLink))),"contain":""}})],1)])],1)])],1)})],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }