<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="14"
    viewBox="0 0 34 14"
  >
    <g fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="3">
      <path stroke="#FFF" d="M2.366 1.5h23.45" />
      <path stroke="#111" d="M10.024 12.5H31.85" />
      <path stroke="#FFF" d="M7.5 12.5h24.351" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "dark-burger"
};
</script>
