<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <defs>
      <linearGradient id="a" x1="9.154%" x2="90.029%" y1="90.847%" y2="9.97%">
        <stop offset="0%" stop-color="#FFC107" />
        <stop offset="50.7%" stop-color="#F44336" />
        <stop offset="100%" stop-color="#9C27B0" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="14.646%"
        x2="85.354%"
        y1="85.354%"
        y2="14.646%"
      >
        <stop offset="0%" stop-color="#FFC107" />
        <stop offset="50.7%" stop-color="#F44336" />
        <stop offset="99%" stop-color="#9C27B0" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="14.648%"
        x2="85.352%"
        y1="85.361%"
        y2="14.648%"
      >
        <stop offset="0%" stop-color="#FFC107" />
        <stop offset="50.7%" stop-color="#F44336" />
        <stop offset="99%" stop-color="#9C27B0" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="nonzero">
      <path
        fill="url(#a)"
        d="M17.188 0H7.813A7.813 7.813 0 0 0 0 7.813v9.375A7.813 7.813 0 0 0 7.813 25h9.375A7.813 7.813 0 0 0 25 17.187V7.813A7.813 7.813 0 0 0 17.187 0zm5.468 17.188a5.475 5.475 0 0 1-5.468 5.468H7.813a5.475 5.475 0 0 1-5.47-5.468V7.813a5.475 5.475 0 0 1 5.47-5.47h9.375a5.475 5.475 0 0 1 5.468 5.47v9.375z"
      />
      <path
        fill="url(#b)"
        d="M12.5 6a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 10.563A4.068 4.068 0 0 1 8.437 12.5 4.067 4.067 0 0 1 12.5 8.437a4.067 4.067 0 0 1 4.063 4.063 4.068 4.068 0 0 1-4.063 4.063z"
      />
      <circle cx="19" cy="6" r="1" fill="url(#c)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "instagram"
};
</script>
