<template>
  <div>
    <div class="hidden-md-and-down" @mouseover="hover = true" @mouseleave="hover = null">
      <v-img class="dark-logo" :src="logoSrc" :style="hover ? { opacity: 0 } : { opacity: 1 }"></v-img>
      <v-img v-show="hover" class="animated" :src="animatedLogoSrc"></v-img>
    </div>

    <div class="hidden-lg-and-up">
      <v-img class="dark-logo" :src="logoSrc"></v-img>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: null,
      logoSrc: require("@assets/images/dark-logo-no-text.svg"),
      animatedLogoSrc: require("@assets/images/dark-logo-no-text-animated.gif")
    };
  }
};
</script>

<style scoped>
.dark-logo {
  position: fixed;
  /* background: grey; */
  top: 58px;
  /* left: 25px; */
  left: 24px;
  width: 30px;
  max-width: 51px;
  min-width: 51px;
  z-index: 9;
}

/* .dark-logo:hover {
  opacity: 0;
} */

.animated {
  position: fixed;
  top: 59.5px;
  left: 26px;
  width: 30px;
  max-width: 46px;
  min-width: 46px;
  z-index: 9;
}

.animated:hover {
  opacity: 1;
}
@media (max-width: 475px) { .dark-logo { top: 33px;} }
</style>
