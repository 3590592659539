var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v(_vm._s(this.project.testimony))]),_c('footer',[_c('span',{class:[
        this.hasTypewriterEffectFinished ? 'client-name' : 'client-name hidden'
      ],style:([
        _vm.$vuetify.breakpoint.width <= '1499'
          ? { fontSize: '18px' }
          : { fontSize: '24px' }
      ])},[_vm._v(_vm._s(_vm.project.clientName))]),_c('div',{class:[
        this.hasTypewriterEffectFinished ? 'client-role' : 'client-role hidden'
      ],style:([
        _vm.$vuetify.breakpoint.width <= '1499'
          ? { fontSize: '14px' }
          : { fontSize: '16px' }
      ])},[_vm._v(_vm._s(_vm.project.clientRole))])])])}
var staticRenderFns = []

export { render, staticRenderFns }