<template>
  <div id="homeContainer" class="home">
    <Header />
    <Logo class="logo" />
    <Sidebar class="sidebar" />
    <div class="black-box-container">
      <Projects />
      <SelfBelief />
      <!-- <Testimonial /> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@components/_header.vue";
import Logo from "@components/_logo.vue";
import Sidebar from "@components/_sidebarNav.vue";
import Projects from "@components/_projects.vue";
import SelfBelief from "@components/_selfBelief.vue";
// import Testimonial from "@components/_testimonial.vue";
import Footer from "@components/_footer.vue";

export default {
  components: {
    Header,
    Logo,
    Sidebar,
    Projects,
    SelfBelief,
    Footer
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }
};
</script>

<style>
.home {
  position: relative;
}

.black-box-container {
  position: relative;
  width: 100%;
  background-color: black;
  z-index: 5;
}

.logo,
.sidebar {
  z-index: 9;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a.social-netowrk-btn:link,
a.social-netowrk-btn:visited {
  opacity: 0.61;
  font-family: "futura-pt", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  /* letter-spacing: -0.43px; */
  margin: 0px;
  transition: all 0.5s;
}
a.social-netowrk-btn:hover,
a.social-netowrk-btn:active {
  /* transform: scale(1.3); */
  transition: all 0.5s;
}

a.social-netowrk-btn:link,
a.social-netowrk-btn:visited {
  margin: 0px;
  text-align: left !important;
  padding: 5px;
}

/* ===================================== */
/* ==== Social Buttons Hover effect ==== */
/* ===================================== */

.Instagram-icon,
.Dribbble-icon,
.LinkedIn-icon,
.Behance-icon,
.Facebook-icon {
  background: #ffffff;
}

.Instagram-icon > .v-btn__content,
.Dribbble-icon > .v-btn__content,
.Behance-icon > .v-btn__content,
.LinkedIn-icon > .v-btn__content,
.Facebook-icon > .v-btn__content {
  /* font-size: 23px; */
  line-height: 1.33;
  transition: all 0.5s;
  display: inline;
}

.Instagram-icon > .v-btn__content {
  background: -webkit-linear-gradient(73deg, #ffc107, #f44336 51%, #9c27b0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Dribbble-icon > .v-btn__content {
  background-color: #ec23c7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Behance-icon > .v-btn__content {
  background-color: #0057ff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.LinkedIn-icon > .v-btn__content {
  background-color: #0077b7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Facebook-icon > .v-btn__content {
  background-color: #0273e7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
