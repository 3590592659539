<template>
  <div v-if="show" id="left-navigation">
    <v-navigation-drawer
      v-if="drawer"
      v-model="drawer"
      :mini-variant="mini"
      hide-overlay
      stateless
      mini-variant-width="90px"
      app
      touchless
    >
      <router-link to="/">
        <v-layout column align-center fill-height class="text-xs-center">
          <transition name="boxAnimate">
            <div
              v-if="innerBoxAnimate"
              class="inner-box"
              ref="innerBox"
              :style="[
                $vuetify.breakpoint.width <= '1264px'
                  ? { maxHeight: '500px' }
                  : ''
              ]"
            ></div>
          </transition>
          <transition name="boxAnimate">
            <div
              v-if="outerBoxAnimate"
              class="outer-box"
              ref="outerBox"
              :style="[
                $vuetify.breakpoint.width <= '1264px'
                  ? { maxHeight: '629px' }
                  : ''
              ]"
            ></div>
          </transition>
          <v-flex>
            <LogoNoText />
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex>
            <v-icon small>$vuetify.icons.BackArrow</v-icon>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </router-link>
    </v-navigation-drawer>
    <div v-else>
      <router-link to="/">
        <LogoNoText class="dark-logo-mobile" />
      </router-link>
    </div>
  </div>
</template>

<script>
import LogoNoText from "@components/_logo-no-text.vue";
import { setTimeout } from "timers";
export default {
  data() {
    return {
      show: null,
      windowWidth: 0,
      drawer: null,
      items: [
        { title: "Home", icon: "dashboard" },
        { title: "About", icon: "question_answer" }
      ],
      mini: true,
      right: null,
      innerBoxAnimate: false,
      outerBoxAnimate: false
    };
  },
  components: {
    LogoNoText
  },
  mounted() {
    // set a timer of 0.5s to show the left side bar after animation is completed
    setTimeout(() => {
      this.show = true;
    }, 1000);

    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
  },
  updated() {
    setTimeout(() => {
      this.innerBoxAnimate = true;
    }, 250);

    setTimeout(() => {
      this.outerBoxAnimate = true;
    }, 500);
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 600) {
        this.drawer = null;
      } else {
        this.drawer = true;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    this.show = false;
  }
};
</script>

<style>
#left-navigation > .v-navigation-drawer--open {
  background-color: #111111;
}

#left-navigation > .v-navigation-drawer {
  background-color: #111111;
}

.dark-logo-mobile {
  top: 65px;
  right: 63px;
}

.inner-box {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 0;
  right: 20%;
  opacity: 0.04;
  background-color: #ffffff;
  max-width: 29px;
  max-height: 600px;
  transform: translate(0, 10%);
  transition: 0.5s linear;
  z-index: 3;
}

.outer-box {
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 0;
  right: 20%;
  opacity: 0.04;
  background-color: #ffffff;
  max-width: 57px;
  max-height: 729px;
  transform: translate(0, 10%);
  transition: 0.5s ease-in-out;
}

@media (max-width: 475px) {
  .dark-logo-mobile {
    top: 35.7px !important;
    right: 25px !important;
  }
}

/* +++++++++++++++++++++++++++++ */
/* +++++ Vue transitions +++++++ */
/* +++++++++++++++++++++++++++++ */

.boxAnimate-enter-active {
  animation: slide-in 0.75s;
}

.boxAnimate-leave-active {
  animation: slide-in 0.75s reverse;
}

@keyframes slide-in {
  from {
    right: 100%;
  }
  to {
    right: 20%;
  }
}
</style>
